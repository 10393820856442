import { addDays, Calendar, Callout, Checkbox, ChoiceGroup, ComboBox, CommandBarButton, ConstrainMode, DatePicker, DefaultButton, defaultCalendarStrings, defaultDatePickerStrings, DetailsList, DetailsListLayoutMode, Facepile, FontIcon, IBasePickerSuggestionsProps, ICalendarDayGridStyles, IChoiceGroupOption, IColumn, IComboBox, IComboBoxOption, IComboBoxStyles, IconButton, IDatePickerStyles, IPersonaProps, IPersonaStyles, IProcessedStyleSet, ISpinButtonStyles, ITag, Label, ListPeoplePicker, Panel, PanelType, PeoplePickerItemSuggestion, Persona, PersonaSize, Pivot, PivotItem, Position, PrimaryButton, SelectableOptionMenuItemType, SelectionMode, Separator, SpinButton, Spinner, SpinnerSize, Stack, StackItem, TagPicker, Text, TextField, Toggle, ValidationState } from "@fluentui/react";
import { FC, useCallback, useContext, useEffect, useRef, useState } from "react";
import { Link, Navigate, Router, useParams, useNavigate, useSearchParams } from "react-router-dom";
import { store } from "../Components/Common/GlobalStateProvider";
import HeadingComponent from "../Components/Common/HeadingComponent";
import { Review } from "../Models/Reviews/Review";
import { ArtifactDownload } from "../Models/TMPArtifacts/ArtifactDownload";
import { LinkedArtifact } from "../Models/TMPArtifacts/LinkedArtifact";
import UserProfile from "../Models/UserProfile";
import { ArtifactService } from "../Services/ArtifactService";
import MeetingsService from "../Services/MeetingsService";
import UserService from "../Services/UserService";
import { getDateFormat, getDateTimeFromDateAndTimeAMPM, getTimeFormat } from "../Utilities/dateutilities";
import * as React from "react";
import { ArtifactInfoComponent } from "./ArtifactDateComponent";
// tinymce imports
import 'tinymce/tinymce';
import 'tinymce/icons/default';
import 'tinymce/themes/silver';
// import 'tinymce/plugins/paste';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/fullscreen';
// import 'tinymce/plugins/hr';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/table';
// import 'tinymce/plugins/textcolor';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/content/default/content.min.css';
import { Editor } from '@tinymce/tinymce-react';
import { QuickNotes } from "../Models/Reviews/QuickNotes";
import { WeaknessType } from "../Models/WeaknessType";
import { ReviewWorkItem } from "../Models/WorkItems/ReviewWorkItem";
import { ReviewStatus } from "../Models/Reviews/ReviewStatus";
import { profile } from "console";
import { useBoolean, useId } from "@fluentui/react-hooks";
import STServices from "../Services/STServices";
import { ServiceTreeService } from "../Models/ServiceTreeService";
import { Slot } from "../Models/Reviews/Slot";
import { TimeZoneConversionDTO } from "../Models/TimeZones/TimeZoneConversionDTO";
import { ReviewSlot } from "../Models/Reviews/ReviewSlot";
import { ServiceTreeServicePickerComponent } from "../Components/Common/ServiceTreeServicePickerComponent";
import { MsPeoplePickerComponent } from "../Components/Common/MsPeoplePickerComponent";
import ReviewDetailUpdate from "../Models/Reviews/ReviewDetailUpdate";
import { ReviewTypeComponent } from "../Components/Common/ReviewTypeComponent";
import { Artifact } from "../Models/TMPArtifacts/Artifact";
import { upload } from "@testing-library/user-event/dist/upload";
import { UploadOrLinkArtifactsComponent } from "../Components/Common/UploadOrLinkArtifactsComponent";
import { RescheduleSlot } from "../Models/Reviews/RescheduleSlot";
import { ReviewRecordStrings } from "../Models/Constants/ReviewRecordStrings";
import { CopyToClipboard } from "../Components/Common/CopyToClipboard";
import { TechTag } from "../Models/TechTag";
import { TechnologyTagsComponent } from "../Components/Common/TechnologyTagsComponent";
import { AvailableSlotsComponent } from "../Components/Common/AvailableSlotsComponent";
import { ServiceReviewHistoryComponent } from "../Components/Common/ServiceReviewHistoryComponent";
import { ReviewTasksnBugsComponent } from "../Components/Common/ReviewTasksnBugsComponent";
import { MeetingTimeCalloutComponent } from "../Components/Common/MeetingTimeCalloutComponent";
import { TimeZoneDropdownComponent } from "../Components/Common/TimeZoneDropdownComponent";
import { TimeDropDownComponent } from "../Components/Common/TimeDropDownComponent";
import { TMPTimeZone } from "../Models/TimeZones/TmpTimeZone";
import { MeetingTime } from "../Models/TimeZones/MeetingTime";
import { SpinnerPrimaryButton } from "../Components/Common/SpinnerPrimaryButton";
import { PreviewQuestionnaire } from "./Questionnaire/PreviewQuestionnaire";
import { RescheduleAdhoc } from "../Models/Reviews/RescheduleAdhoc";
import { ServicePriorityStrings } from "../Models/Constants/ServicePriorityStrings";
import { DisplayMessageType, MessageComponent, useMessages } from "../Components/Common/MessageComponent";
import { ReviewTypeStrings } from "../Models/Constants/ReviewTypeStrings";
import { FileTypeLogoComponent } from "../Components/Common/FileTypeLogoComponent";
import { ArtifactTypeStrings } from "../Models/Constants/ArtifactTypeStrings";
import { FollowUps } from "../Models/Reviews/FollowUps";
import { isEmptyOrSpaces } from "../Utilities/textUtilities";
import { TeamMetadata } from "../Models/TeamMetadata";
import { TasksBugsCreateComponent } from "../Components/Common/TasksBugsCreateComponent";
import { CountdownTimer } from "../Components/Common/CountdownTimer";
import { DraftFeedback } from "../Models/Reviews/DraftFeedback";
import { ReviewStatusStrings } from "../Models/Constants/ReviewStatusStrings";
import { OtherUserScribbleComponent } from "../Components/Reviewer/OtherUserScribbleComponent";
import { ReviewerDraftFollowUpsComponent } from "../Components/Reviewer/ReviewerDraftFollowUpsComponent";
import { FollowUpCreateComponent } from "../Components/Reviewer/FollowUpCreateComponent";
import { ReviewerDraftTasksAndBugsComponent } from "../Components/Reviewer/ReviewerDraftTasksAndBugsComponent";
import { text } from "stream/consumers";
import { PublishTasksAndBugs } from "../Models/Reviews/PublishTasksAndBugs";
import { PublishFollowups } from "../Models/Reviews/PublishFollowups";
import { BusinessResult } from "../Models/common/BusinessResult";
import { SpinnerCommandBarButton } from "../Components/Common/SpinnerCommandBarButton";
import { InternalNotesComponent } from "../Components/Common/InternalNotesComponent";

// // // TinyMCE so the global var exists
// //   // eslint-disable-next-line no-unused-vars
// //   import 'tinymce/tinymce';

// //   // Theme
// //   import 'tinymce/themes/silver';
// //   // Toolbar icons
// //   import 'tinymce/icons/default';
// //   // Editor styles
// //   import 'tinymce/skins/ui/oxide/skin.min.css';

// importing the plugin js.
// import tinymce from '../assets/tinymce/tinymce.min.js';
// // import 'tinymce/plugins/advlist';
// // import 'tinymce/plugins/anchor';
// // import 'tinymce/plugins/autolink';
// // import 'tinymce/plugins/autoresize';
// // import 'tinymce/plugins/autosave';
// // import 'tinymce/plugins/charmap';
// // import 'tinymce/plugins/code';
// // import 'tinymce/plugins/codesample';
// // import 'tinymce/plugins/directionality';
// // import 'tinymce/plugins/emoticons';
// // import 'tinymce/plugins/fullscreen';
// // import 'tinymce/plugins/help';
// // import 'tinymce/plugins/image';
// // import 'tinymce/plugins/importcss';
// // import 'tinymce/plugins/insertdatetime';
// // import 'tinymce/plugins/link';
// // import 'tinymce/plugins/lists';
// // import 'tinymce/plugins/media';
// // import 'tinymce/plugins/nonbreaking';
// // import 'tinymce/plugins/pagebreak';
// // import 'tinymce/plugins/preview';
// // import 'tinymce/plugins/quickbars';
// // import 'tinymce/plugins/save';
// // import 'tinymce/plugins/searchreplace';
// // import 'tinymce/plugins/table';
// // import 'tinymce/plugins/template';
// // import 'tinymce/plugins/visualblocks';
// // import 'tinymce/plugins/visualchars';
// // import 'tinymce/plugins/wordcount';

// // import { Editor } from "@tinymce/tinymce-react";
// import tinymce from '../assets/tinymce/tinymce.min.js';
// import '../assets/tinymce/icons/default';
// import '../assets/tinymce/themes/silver';
// import '../assets/tinymce/plugins/advlist';
// import '../assets/tinymce/plugins/anchor';
// import '../assets/tinymce/plugins/autolink';
// import '../assets/tinymce/plugins/autoresize';
// import '../assets/tinymce/plugins/autosave';
// import '../assets/tinymce/plugins/charmap';
// import '../assets/tinymce/plugins/code';
// import '../assets/tinymce/plugins/codesample';
// import '../assets/tinymce/plugins/directionality';
// import '../assets/tinymce/plugins/emoticons';
// import '../assets/tinymce/plugins/fullscreen';
// import '../assets/tinymce/plugins/help';
// import '../assets/tinymce/plugins/image';
// import '../assets/tinymce/plugins/importcss';
// import '../assets/tinymce/plugins/insertdatetime';
// import '../assets/tinymce/plugins/link';
// import '../assets/tinymce/plugins/lists';
// import '../assets/tinymce/plugins/media';
// import '../assets/tinymce/plugins/nonbreaking';
// import '../assets/tinymce/plugins/pagebreak';
// import '../assets/tinymce/plugins/preview';
// import '../assets/tinymce/plugins/quickbars';
// import '../assets/tinymce/plugins/save';
// import '../assets/tinymce/plugins/searchreplace';
// import '../assets/tinymce/plugins/table';
// import '../assets/tinymce/plugins/template';
// import '../assets/tinymce/plugins/visualblocks';
// import '../assets/tinymce/plugins/visualchars';
// import '../assets/tinymce/plugins/wordcount';
export const ReviewDetailsPage: FC = (): JSX.Element => {
  let reviewService = new MeetingsService();
  let userService = new UserService();
  let artifactService = new ArtifactService();
  let stService = new STServices();
  const navigate = useNavigate();
  const stackTokens = { childrenGap: 10, };
  const spinButtonStyles: Partial<ISpinButtonStyles> = { spinButtonWrapper: { width: 'auto' } };
  const datePickerStyles: Partial<IDatePickerStyles> = { root: { width: 'auto', marginTop: -2 } };
  const gettingcurrentdate = new Date();

  var { appState, appdispatch } = useContext(store);
  let { reviewId, serviceId } = useParams();
  const [queryParams] = useSearchParams();

  const [isSavingReview, isSavingReviewSet] = useState<boolean>(false);
  const [dataReview, dataReviewSet] = useState<Review>();
  const [dataReviewRaw, dataReviewRawSet] = useState<Review>();
  const [teamMetadata, teamMetadataSet] = useState<TeamMetadata>();

  const [isReviewFound, isReviewFoundSet] = useState<boolean>(true);
  const loadReview = async (reviewId: string | undefined, serviceId: string | undefined) => {
    var result: BusinessResult<Review> | undefined;
    if (reviewId && reviewId.length > 0 && serviceId && serviceId.length > 0) {
      result = await reviewService.getReview(reviewId, serviceId);

    }
    else if (reviewId && reviewId.length > 0) {
      result = await reviewService.getReviewById(reviewId);
    }
    else {
      let queryParamReviewId = queryParams.get('reviewId');
      if (queryParamReviewId !== null && queryParamReviewId.length > 0) {
        result = await reviewService.getReviewById(queryParamReviewId);
        reviewId = queryParamReviewId;
      }
    }

    if (result && !result.hasErrors && result.result) {
      isReviewFoundSet(true);
      dataReviewRawSet(result.result);
      if (serviceId === undefined || serviceId.length == 0) {
        serviceId = result.result.service?.serviceId
      }
    }
    else {
      isReviewFoundSet(false);
      if (result && result.hasErrors && result.businessErrors && result.businessErrors.length > 0 && result.businessErrors[0].message) {
        displayMessagesSet([{ message: result.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
      }
      else {
        displayMessagesSet([{ message: "Could not load the review", messageType: DisplayMessageType.Error }]);
      }
    }
  }

  const loadTeamMetadata = async (teamId: string) => {
    var response = await reviewService.getTeamMetaData(teamId);
    if (response && !response.hasErrors && response.result) {
      teamMetadataSet(response.result);
    }
    else {
      if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
        displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
      }
      else {
        displayMessagesSet([{ message: "The teammetadata details could not be fetched", messageType: DisplayMessageType.Error }]);
      }
    }
  }

  const [userProfiles, userProfilesSet] = useState<UserProfile[]>([]);
  const [reviewerProfiles, reviewerProfilesSet] = useState<UserProfile[]>([]);
  const [requestorPersona, requestorPersonaSet] = useState<IPersonaProps>(
    {
      text: appState.userProfile?.displayName
    });

  const [otherTeamMembers, otherTeamMembersSet] = useState<IPersonaProps[]>([]);

  const [isFetchingDownloadableArtifacts, isFetchingDownloadableArtifactsSet] = useState<boolean>(true);
  const [dataDownloadableArtifacts, dataDownloadableArtifactsSet] = useState<Artifact[]>([]);



  const editorRef = useRef<any>(null);
  const [contentEditor, setContentEditor] = useState<string>();
  const handleEditorChange = (content: string, editor: any): void => {
    setContentEditor(content);
  }

  const [notesToTeam, notesToTeamSet] = useState<string>();

  const [cmbOptionsReviewStatus, cmbOptionsReviewStatusSet] = useState<IComboBoxOption[]>([]);
  const [cmbSelectedOptionReviewStatus, cmbSelectedOptionReviewStatusSet] = useState<string | number>();

  const [cmbOptionsServicePriority, cmbOptionsServicePrioritySet] = useState<IComboBoxOption[]>([]);
  const [cmbSelectedOptionServicePriority, cmbSelectedOptionServicePrioritySet] = useState<string | number>();

  const [timeSpentValue, timeSpentValueSet] = useState<string>('0');

  const today: Date = new Date(new Date());
  const maxDate = today;
  const minDate = today;
  const [reviewCompleteDate, reviewCompleteDateSet] = useState<Date | undefined>();

  const suggestionProps: IBasePickerSuggestionsProps = {
    suggestionsHeaderText: 'Suggested People',
    mostRecentlyUsedHeaderText: 'Suggested Contacts',
    noResultsFoundText: 'No results found',
    loadingText: 'Loading',
    showRemoveButtons: true,
    suggestionsAvailableAlertText: 'People Picker Suggestions available',
    suggestionsContainerAriaLabel: 'Suggested contacts',
  };
  const personaStyles: Partial<IPersonaStyles> = {
    root: {
      height: 'auto',
    },
    secondaryText: {
      height: 'auto',
      whiteSpace: 'normal',
    },
    primaryText: {
      height: 'auto',
      whiteSpace: 'normal',
    },
  };

  const [dataSelectedReviewers, dataSelectedReviewersSet] = useState<UserProfile[]>([]);
  const [selectedReviewers, selectedReviewersSet] = useState<IPersonaProps[]>([]);


  const [actionItem, actionItemSet] = useState<string>();
  const [followUp, followUpSet] = useState<string>();

  // const [followUps, followUpsSet] = useState<QuickNotes[]>([]);
  // const [actionItems, actionItemsSet] = useState<QuickNotes[]>([]);

  // if any of the two below get updated. auto save feedback
  const [draftDataWorkItems, draftDataWorkItemsSet] = useState<ReviewWorkItem[]>([]);
  const [followUpToServiceTeam, followUpToServiceTeamSet] = useState<FollowUps[]>([]);

  const [currentUserDraftDataWorkItems, currenUserDraftDataWorkItemsSet] = useState<ReviewWorkItem[]>([]);
  const [currentUserFollowUpToServiceTeam, currentUserFollowUpToServiceTeamSet] = useState<FollowUps[]>([]);

  useEffect(() => {

    if (draftDataWorkItems && draftDataWorkItems.length > 0) {
      var currentuserwkItems = draftDataWorkItems.filter(x => x.createdBy === appState?.userProfile?.userPrincipalName);
      if (currentuserwkItems && currentuserwkItems.length > 0) {
        currenUserDraftDataWorkItemsSet(currentuserwkItems);
      }
    }

    if (followUpToServiceTeam && followUpToServiceTeam.length > 0) {
      var currentuserfollowups = followUpToServiceTeam.filter(x => x.createdBy === appState?.userProfile?.userPrincipalName);
      currentUserFollowUpToServiceTeamSet(currentuserfollowups);
    }
  }, [draftDataWorkItems, followUpToServiceTeam]);

  const [dataWeaknessTypes, dataWeaknessTypesSet] = useState<WeaknessType[]>([]);
  const [workItemForEdit, workItemForEditSet] = useState<ReviewWorkItem>();
  const [followupItemForEdit, followupItemForEditSet] = useState<FollowUps>();

  const [dataWorkItems, dataWorkItemsSet] = useState<ReviewWorkItem[]>([]);

  const [isReviewDetailEditModalOpen, { setTrue: showReviewDetailEditModal, setFalse: hideReviewDetailEditModal }] = useBoolean(false);
  const [isMeetingDetailEditModalOpen, { setTrue: showMeetingDetailEditModal, setFalse: hideMeetingDetailEditModal }] = useBoolean(false);
  const [isAdhocRescheduleEditModalOpen, { setTrue: showAdhocRescheduleEditModal, setFalse: hideAdhocRescheduleEditModal }] = useBoolean(false);

  const [isDeleteModalOpen, { setTrue: showDeleteModal, setFalse: hideDeleteModal }] = useBoolean(false);
  useEffect(() => {
    if (!isDeleteModalOpen) {
      confirmDeleteTextSet("");
    }
  }, [isDeleteModalOpen])

  const [isTasksNBugsModalOpen, { setTrue: showTasksNBugsModalModal, setFalse: hideTasksNBugsModalModal }] = useBoolean(false);
  const [isTasksNBugsModelPinned, { setTrue: pinTasksNBugsModalModal, setFalse: unPinTasksNBugsModalModal }] = useBoolean(false);

  const [additionalRequestDetails, additionalRequestDetailsSet] = useState<string>();
  const onAdditionalDetailsChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    additionalRequestDetailsSet(newValue);
  }

  const [confirmDeleteText, confirmDeleteTextSet] = useState<string>();
  const onConfirmDeleteTextChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    confirmDeleteTextSet(newValue);
  }

  const [selectedTechnologyTags, selectedTechnologyTagsSet] = useState<TechTag[]>([]);
  const [dataSelectedSlot, dataSelectedSlotSet] = useState<Slot>();

  const onActionItemChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    actionItemSet(newValue);
  }
  const onFollowUpChange = (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string): void => {
    followUpSet(newValue);
  }
  const addFollowUp = (text?: string) => {
    if (text && text.trim().length > 0) {
      let id = crypto.randomUUID().toString();

      followUpToServiceTeamSet([...followUpToServiceTeam, {
        id: id,
        question: text,
        createdBy: appState?.userProfile?.userPrincipalName,
        modifiedBy: appState?.userProfile?.userPrincipalName,
      }])

      followUpSet("");
    }
  }

  const addActionItem = (text?: string) => {
    if (text && text.trim().length > 0) {
      let refId = crypto.randomUUID();

      let dWkItm: ReviewWorkItem = {
        referenceId: refId,
        title: text,
        createdBy: appState?.userProfile?.userPrincipalName,
        modifiedBy: appState?.userProfile?.userPrincipalName,
      };
      draftDataWorkItemsSet([...draftDataWorkItems, dWkItm]);
      actionItemSet("");
    }
  }

  const onNotesToTeamChange = (content: string, editor: any): void => {
    notesToTeamSet(content);
  }

  const onReviewStatusChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
    if (option) {
      cmbSelectedOptionReviewStatusSet(option.key);
    }
  };

  const onServicePriorityChange = (event: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number): void => {
    if (option) {
      cmbSelectedOptionServicePrioritySet(option.key);
    }
  };

  const onTimeSpentChange = (event: React.SyntheticEvent<HTMLElement>, newValue?: string) => {
    if (newValue !== undefined) {
      // In reality this might have some additional validation or other special handling
      timeSpentValueSet(newValue);
    }
  }

  const loadUserProfiles = async (userAliases: string[]) => {
    var userProfiles = await userService.getUserProfiles(userAliases);
    userProfilesSet(userProfiles);
  }

  const loadReviewerProfiles = async (userAliases: string[]) => {
    var userProfiles = await userService.getUserProfilesByUPNs(userAliases);
    reviewerProfilesSet(userProfiles);
  }

  const [isNoArtifacts, isNoArtifactsSet] = useState<boolean>(false);
  const loadArtifactsForDownload = async (linkedArtifacts: LinkedArtifact[] | undefined) => {
    isNoArtifactsSet(false);
    isFetchingDownloadableArtifactsSet(true);
    if (linkedArtifacts && linkedArtifacts.length > 0) {
      var newArtifacts = linkedArtifacts.filter(x => !dataDownloadableArtifacts.some(y => y.artifactId === x.tmReviewLink?.artifactId));
      if (newArtifacts && newArtifacts.length > 0) {
        var response = await artifactService.fetchArtifactsForDownload(newArtifacts);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
          console.log(response.result);
          dataDownloadableArtifactsSet([...dataDownloadableArtifacts, ...response.result]);
        }
        else if (response && !response.hasErrors && response.result && response.result.length == 0) {
          isNoArtifactsSet(true);
        }
        else {
          if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
            displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
          }
          else {
            displayMessagesSet([{ message: "Could not load Artifact(s) fo the review", messageType: DisplayMessageType.Error }]);
          }
        }
      }
    }
    isFetchingDownloadableArtifactsSet(false);
  }

  const loadReviewStatus = (isReviewComplate: boolean, isReviewReopened: boolean, isReviewNoShow: boolean) => {
    var reviewOptions: IComboBoxOption[] = [];
    if (!isReviewComplate && !isReviewReopened && !isReviewNoShow) {
      reviewOptions = [
        {
          key: ReviewStatusStrings.New,
          text: ReviewStatusStrings.New
        },
        {
          key: ReviewStatusStrings.InProgress,
          text: ReviewStatusStrings.InProgress
        },
        {
          key: ReviewStatusStrings.Completed,
          text: ReviewStatusStrings.Completed
        },
        {
          key: ReviewStatusStrings.NoShow,
          text: ReviewStatusStrings.NoShow
        },
        {
          key: ReviewStatusStrings.Abandoned,
          text: ReviewStatusStrings.Abandoned
        },
      ];
    }
    else if (isReviewReopened) {
      reviewOptions = [
        {
          key: ReviewStatusStrings.Completed,
          text: ReviewStatusStrings.Completed
        },
        {
          key: ReviewStatusStrings.ReOpened,
          text: ReviewStatusStrings.ReOpened
        },
        {
          key: ReviewStatusStrings.Abandoned,
          text: ReviewStatusStrings.Abandoned
        }
      ];
    }
    else if (isReviewComplate) {
      reviewOptions = [
        {
          key: ReviewStatusStrings.ReOpened,
          text: ReviewStatusStrings.ReOpened
        },
        {
          key: ReviewStatusStrings.Completed,
          text: ReviewStatusStrings.Completed
        }
      ];
    }
    else if (isReviewNoShow) {
      reviewOptions = [
        {
          key: ReviewStatusStrings.NoShow,
          text: ReviewStatusStrings.NoShow
        },
        {
          key: ReviewStatusStrings.ReOpened,
          text: ReviewStatusStrings.ReOpened
        },
        {
          key: ReviewStatusStrings.Abandoned,
          text: ReviewStatusStrings.Abandoned
        }
      ];
    }

    cmbOptionsReviewStatusSet(reviewOptions);
  }

  const loadServicePriority = () => {
    var servicePriorityOptions: IComboBoxOption[] = [
      {
        key: 'Excluded',
        text: 'Excluded'
      },
      {
        key: ServicePriorityStrings.Priority1,
        text: 'Priority 1'
      },
      {
        key: ServicePriorityStrings.Priority2,
        text: 'Priority 2'
      },
      {
        key: ServicePriorityStrings.Priority3,
        text: 'Priority 3'
      },
      {
        key: ServicePriorityStrings.Priority4,
        text: 'Priority 4'
      },
    ];

    cmbOptionsServicePrioritySet(servicePriorityOptions);

  }
  const loadWeaknessTypes = async () => {
    var response = await reviewService.getWeaknesses();
    if (response && !response.hasErrors && response.result && response.result.length > 0) {
      dataWeaknessTypesSet(response.result);
    }
    else {
      if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
        displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
      }
      else {
        displayMessagesSet([{ message: "Could not load weakness types.", messageType: DisplayMessageType.Error }]);
      }
    }
  }
  // begin changes for isFutureDatedReview
  // settings for opening and closing panels of isFutureDatedReviewModal
  const [isFutureDateModalOpen, { setTrue: showisFutureDateReviewModal, setFalse: hideisFutureDateReviewModal }] = useBoolean(false);
  const [isFDRProceedClicked, isFDRProceedClickedSet] = useState<boolean>(false);
  const [isFDReview, isFDReviewSet] = useState<boolean>(false);
  const evaluateisFDreview = () => {
    // begin changes for isFutureDatedReviewModal
    // Write a check here to compare the review isfuture Dated or not.
    if (cmbSelectedOptionReviewStatus == ReviewStatusStrings.Completed && dataReview && dataReview.meetingsData && dataReview.meetingsData.dateOfReview && new Date(dataReview.meetingsData.dateOfReview) > new Date()) {
      isFDReviewSet(true);
      return true;
    }
    // end of changes for isFutureDatedReviewModal
  }
  // End of changes for FutureDatedReview

  const onReviewSaveClick = async () => {
    reset();
    let followUpsArray: string[] = [];
    let actionItemsArray: string[] = [];

    if (cmbSelectedOptionReviewStatus === undefined) {
      displayMessagesSet([{ message: "Please select a Review Status", messageType: DisplayMessageType.Warning }]);
      return;
    }

    if (cmbSelectedOptionReviewStatus == ReviewStatusStrings.Completed && reviewCompleteDate === undefined) {
      displayMessagesSet([{ message: "Please set review completed date", messageType: DisplayMessageType.Warning }]);
      return;
    }
    // begin changes for isFutureDatedReviewModal
    // Write a check here to compare the review isfuture Dated or not.
    // evaluateisFDreview(); This was commented because useeffect is not working, hence changing the logic to return boolean value.
    if (evaluateisFDreview() && !isFDRProceedClicked) {
      showisFutureDateReviewModal();
      return;
    }
    // end of changes for isFutureDatedReviewModal

    var reviewerUpdates: ReviewStatus =
    {
      reviewCompletedOn: reviewCompleteDate,
      internalNotes: contentEditor,
      timeSpent: timeSpentValue,
      workItems: dataWorkItems,
      followUps: followUpsArray,
      actionItems: actionItemsArray,
      notesToRequestor: notesToTeam,
      reviewStatus: cmbSelectedOptionReviewStatus as string,
      servicePriority: cmbSelectedOptionServicePriority as string,
      reviewers: dataSelectedReviewers,
      followUpsToServiceTeam: followUpToServiceTeam,
      reviewId: reviewId,
      serviceId: serviceId,
      eTag: dataReview?.eTag,
    }
    var response = await reviewService.saveReviewerUpdates(reviewerUpdates);
    if (response && !response.hasErrors && response.result) {
      displayMessagesSet([{ message: "Successfully updated the review.", messageType: DisplayMessageType.Success }]);
      dataReviewRawSet(response.result);

    }
    else {
      if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
        displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
      }
      else {
        displayMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
      }
    }
  }

  const loadReviewWithLocalTime = async (review: Review) => {
    var meetingDataWithLocalTime = await reviewService.loadLocalTimeForReviews([review]);
    if (meetingDataWithLocalTime && meetingDataWithLocalTime.length > 0) {
      dataReviewSet(meetingDataWithLocalTime[0]);
    }
  }

  function reset() {
    //isNoArtifactsSet(false);
    displayMessagesSet([]);
    displayAdhocReschedulePanelMessagesSet([]);
    displayReschedulePanelMessagesSet([]);
    displayEditPanelMessagesSet([]);
  }

  useEffect(() => {
    reset();
    loadReviewStatus(false, false,false);
    loadServicePriority();
    loadWeaknessTypes();
    console.log("useeffect []");
    autoRefreshFeedBack();
    loadReview(reviewId, serviceId);

  }, []);
  useEffect(() => {
    var userAliases: string[] = [];

    var requestorAlias = dataReview?.requestor?.userPrincipalName.split('@')[0];
    if (requestorAlias && requestorAlias.length > 0) {
      userAliases.push(requestorAlias);
    }

    dataReview?.otherTeamMembers?.forEach(member => {
      var memberAlias = member.userPrincipalName.split('@')[0];
      userAliases.push(memberAlias);
    });

    if (userAliases && userAliases.length > 0) {
      loadUserProfiles(userAliases);
    }

    // userWorkItemsColumnsSet([...workItemsColumns.filter(x => x.key != "QuickActions")]);

    // Load existing data.
    if (dataReview) {

      if (dataReview.linkedArtifacts && dataReview.linkedArtifacts.length > 0) {
        isNoArtifactsSet(false);

        loadArtifactsForDownload(dataReview.linkedArtifacts);
      }
      else {
        isNoArtifactsSet(true);
        if (dataReview.reviewType == ReviewTypeStrings.ThreatModelReview) {
          displayMessagesSet([{ message: "Review cannot be marked complete with out the threat model file.", messageType: DisplayMessageType.Warning }]);
        }
      }

      if (dataReview.tmReviewActions && dataReview.tmReviewActions.length > 0) {
        dataWorkItemsSet(dataReview?.tmReviewActions)
      }

      if (dataReview.reviewStatus) {
        cmbSelectedOptionReviewStatusSet(dataReview.reviewStatus);
      }

      if (dataReview.service?.sdlPrioritization) {
        cmbSelectedOptionServicePrioritySet(dataReview.service.sdlPrioritization);
      }

      if (dataReview.reviewType !== undefined) {
        selectedTmMeetingTypeSet(dataReview.reviewType.toString());
      }

      if (dataReview.timeSpent) {
        timeSpentValueSet(dataReview.timeSpent)
      }

      if (dataReview.reviewCompletedOn) {
        var dateComplete = new Date(dataReview.reviewCompletedOn);
        if (dateComplete) {
          reviewCompleteDateSet(new Date(dateComplete.getFullYear(), dateComplete.getMonth(), dateComplete.getDate()));
        }
      }

      if (dataReview.reviewers && dataReview.reviewers.length > 0) {
        let userUpns: string[] = [];
        dataReview.reviewers.forEach(member => {
          var memberAlias = member.userPrincipalName;
          userUpns.push(memberAlias);
        });

        if (userUpns && userUpns.length > 0) {
          loadReviewerProfiles(userUpns);
        }

        dataSelectedReviewersSet([...dataReview.reviewers]);
      }

      if (dataReview.notesToRequestor) {
        notesToTeamSet(dataReview.notesToRequestor);
      }

      if (dataReview.technologyTags) {
        selectedTechnologyTagsSet(dataReview.technologyTags);
      }

      if (dataReview.reviewStatus === ReviewStatusStrings.Completed) {
        loadReviewStatus(true, false, false);
      }

      if (dataReview.reviewStatus === ReviewStatusStrings.ReOpened) {
        loadReviewStatus(false, true,false);
      }
      if (dataReview.reviewStatus === ReviewStatusStrings.NoShow) {
        loadReviewStatus(false, false,true);
      }

      if (dataReview.service && dataReview.service.serviceId && dataReview.service.serviceName) {
        var tSelectedService: ITag = {
          key: dataReview.service.serviceId,
          name: dataReview.service.serviceName
        };
        selectedServiceSet([tSelectedService]);
        dataSelectedServiceTreeServiceSet(dataReview.service);
      }

      if (dataReview.interactingServices && dataReview.interactingServices.length > 0) {
        selectedOtherInteractionServiceTreeServicesSet(dataReview.interactingServices);
      }
      else {
        selectedOtherInteractionServiceTreeServicesSet([]);
      }
    }

    isSavingReviewSet(false);
  }, [dataReview]);
  useEffect(() => {
    if (dataReviewRaw) {
      loadReviewWithLocalTime(dataReviewRaw);
      if (dataReviewRaw.securityReviewTeam?.id) {
        loadTeamMetadata(dataReviewRaw?.securityReviewTeam?.id);
      }
    }
  }, [dataReviewRaw]);
  useEffect(() => {
    var requestorProfile = userProfiles.filter(x => x.userPrincipalName == dataReview?.requestor?.userPrincipalName);
    if (requestorProfile && requestorProfile.length > 0) {
      var requestorPersona: IPersonaProps = {
        text: requestorProfile[0].displayName,
        imageUrl: requestorProfile[0].photo
      }

      requestorPersonaSet(requestorPersona);
    }
    else {
      var requestorPersona: IPersonaProps = {
        text: dataReview?.requestor?.userPrincipalName
      }

      requestorPersonaSet(requestorPersona);
    }

    var otherProfiles = userProfiles.filter(x => x.userPrincipalName !== dataReview?.requestor?.userPrincipalName);
    var otherPersona: IPersonaProps[] = [];
    if (otherProfiles && otherProfiles.length > 0) {
      otherProfiles.forEach(profile => {
        let otherProf: IPersonaProps = {
          key: profile.id,
          text: profile.displayName,
          imageUrl: profile.photo
        }

        otherPersona.push(otherProf);
      });

      dataSelectedOtherTeamMembersSet(otherProfiles);
      otherTeamMembersSet(otherPersona);
    }

  }, [userProfiles]);
  useEffect(() => {
    var tpersonaProps: IPersonaProps[] = [];
    selectedReviewersSet(tpersonaProps);
    reviewerProfiles.forEach(profile => {
      let reviewProf: IPersonaProps = {
        text: profile.displayName,
        imageUrl: profile.photo
      }

      tpersonaProps.push(reviewProf);
    });

    selectedReviewersSet(tpersonaProps);
  }, [reviewerProfiles])
  useEffect(() => {
    isFetchingDownloadableArtifactsSet(false);
    var hasTm7OrTm8 = dataDownloadableArtifacts.filter(x => x.fileName?.toLowerCase()?.endsWith(".tm7") || x.fileName?.toLowerCase()?.endsWith(".tm8"))
    if (hasTm7OrTm8 && hasTm7OrTm8.length > 0) {
      if (dataReview && dataReview.reviewType == ReviewTypeStrings.ThreatModelReview) {
        displayMessagesSet([]);
      }
    }
    else {
      if (dataReview && dataReview.reviewType == ReviewTypeStrings.ThreatModelReview) {
        displayMessagesSet([{ message: "Review cannot be marked complete with out the threat model file.", messageType: DisplayMessageType.Warning }]);
      }
    }

  }, [dataDownloadableArtifacts])

  const [displayMessages, displayMessagesSet] = useMessages();
  const [displayEditPanelMessages, displayEditPanelMessagesSet] = useMessages();
  const [displayFDReviewPanelMessages, displayFDReviewPanelMessagesSet] = useMessages();
  const [displayAdhocReschedulePanelMessages, displayAdhocReschedulePanelMessagesSet] = useMessages();
  const [displayReschedulePanelMessages, displayReschedulePanelMessagesSet] = useMessages();
  const [displayDraftTasksAndBugsErrors, displayDraftTasksAndBugsErrorsSet] = useMessages();
  const [displayDraftFolloupErrors, displayDraftFolloupErrorsSet] = useMessages();
  const [displayDeletePanelMessages, displayDeletePanelMessagesSet] = useMessages();

  const onTasksNBugsFooterContent = () => {
    return (
      <></>
    );
  }

  const onTasksNBugsFNavigationContent = () => {
    return (
      <div className="row" style={{ marginRight: 10, marginTop: 10 }}>
        <div className="d-flex">
          <Stack horizontal tokens={stackTokens} className="ms-auto">
            {
              isTasksNBugsModelPinned &&
              <FontIcon iconName="Pinned" onClick={unPinTasksNBugsModalModal} />
            }
            {
              !isTasksNBugsModelPinned &&
              <FontIcon iconName="Pin" onClick={pinTasksNBugsModalModal} />
            }
            <FontIcon iconName="Cancel" />
          </Stack>
        </div>
      </div>
    );
  }

  const onReviewDeleteFooterContent = () => {
    return (
      <Stack horizontal tokens={stackTokens}>
        <SpinnerPrimaryButton text="Delete" onclick={deleteReview} executingText="Deleting the review" />
        <DefaultButton onClick={hideDeleteModal}>Cancel</DefaultButton>
      </Stack>
    );
  }
  const deleteReview = async () => {
    displayDeletePanelMessagesSet([]);
    if (confirmDeleteText && confirmDeleteText.length > 0 && confirmDeleteText == 'yes') {
      if (reviewId && serviceId) {
        var response = await reviewService.deleteReview(reviewId, serviceId);
        if (response && !response.hasErrors && response.result) {
          displayDeletePanelMessagesSet([{ message: "The review has been deleted succesfully", messageType: DisplayMessageType.Success }]);
          navigate("/");
        }
        else {

          if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
            displayDeletePanelMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
          }
          else {
            displayDeletePanelMessagesSet([{ message: "The review could not be deleted", messageType: DisplayMessageType.Error }]);
          }

        }
      }
    }
    else {
      displayDeletePanelMessagesSet([{ message: 'Please confirm delete by typing yes in the text box', messageType: DisplayMessageType.Warning }]);
    }
  }

  const onReviewDetailFooterContent = () => {
    return (
      <Stack horizontal tokens={stackTokens}>
        <SpinnerPrimaryButton text="Save" onclick={editReviewDetail} executingText="Saving the review details" />
        <DefaultButton onClick={hideReviewDetailEditModal}>Cancel</DefaultButton>
      </Stack>
    );
  }
  const editReviewDetail = async () => {

    if (dataSelectedServiceTreeService === undefined
      || dataSelectedServiceTreeService.serviceId == undefined
      || dataSelectedServiceTreeService.serviceId.length == 0) {
      displayEditPanelMessagesSet([{ message: "Please select the service", messageType: DisplayMessageType.Warning }]);
      return;
    }

    if (selectedTmMeetingType === undefined) {
      displayEditPanelMessagesSet([{ message: "Please select the meeting type", messageType: DisplayMessageType.Warning }]);
      return;
    }

    var updateReviewDetailObj: ReviewDetailUpdate = {
      reviewId: reviewId,
      serviceId: serviceId,
      service: dataSelectedServiceTreeService,
      reviewType: selectedTmMeetingType,
      otherTeamMembers: dataSelectedOtherTeamMembers,
      interactingServices: selectedOtherInteractionServiceTreeServices,
      technologyTags: selectedTechnologyTags,
      additionalDetails: additionalRequestDetails,
      eTag: dataReview?.eTag,
    }

    var response = await reviewService.updateReviewDetail(updateReviewDetailObj);
    if (response && !response.hasErrors) {

      displayMessagesSet([{ message: "Successfully updated the review.", messageType: DisplayMessageType.Success }]);
      hideReviewDetailEditModal();
      if (serviceId !== updateReviewDetailObj.service?.serviceId) {
        if (response.result && response.result.id && updateReviewDetailObj.service?.serviceId) {
          navigate("/reviewdetail/" + response.result.id + "/" + updateReviewDetailObj.service?.serviceId)
        }
      }
      else {
        dataReviewRawSet(response.result);
      }
    }
    else {
      if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
        displayEditPanelMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
      }
      else {
        displayEditPanelMessagesSet([{ message: "The review details could not be updated", messageType: DisplayMessageType.Error }]);
      }
    }
  }

  const onMeetingDetailFooterContent = () => (
    <Stack horizontal tokens={stackTokens}>
      <SpinnerPrimaryButton text="Save" onclick={rescheduleMeeting} executingText="Rescheduling the meeting" />
      <DefaultButton onClick={() => { dataSelectedSlotSet(undefined); hideMeetingDetailEditModal(); }}>Cancel</DefaultButton>
    </Stack>
  );
  const rescheduleMeeting = async () => {

    if (reviewId === undefined || serviceId === undefined) {
      displayReschedulePanelMessagesSet([{ message: "Could not determine review parameters ReviewId or ServiceId", messageType: DisplayMessageType.Error }]);
      return;
    }

    if (dataSelectedSlot === undefined || dataSelectedSlot.id == undefined || dataSelectedSlot.partitionKey == undefined) {
      displayReschedulePanelMessagesSet([{ message: "Please select a new slot from the list of slots displayed upon selecting new date.", messageType: DisplayMessageType.Warning }]);
      return;
    }

    var rescheduleDTO: RescheduleSlot = {
      reviewId: reviewId,
      serviceId: serviceId,
      newSlot: dataSelectedSlot,
    };

    var response = await reviewService.reScheduleMeeting(rescheduleDTO);
    if (response && !response.hasErrors && response.result) {
      displayMessagesSet([{ message: "Successfully rescheduled the review", messageType: DisplayMessageType.Success }]);
      dataReviewRawSet(response.result);
    }
    else {
      if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
        displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
      }
      else {
        displayMessagesSet([{ message: "Could not reschedule the review.", messageType: DisplayMessageType.Error }]);
      }
    }
    hideMeetingDetailEditModal();
  }

  const [dataSuggestedServiceTreeServices, dataSuggestedServiceTreeServicesSet] = useState<ServiceTreeService[]>([]);
  const [dataSelectedServiceTreeService, dataSelectedServiceTreeServiceSet] = useState<ServiceTreeService>();
  const [selectedService, selectedServiceSet] = useState<ITag[] | undefined>([]);

  const [selectedTmMeetingType, selectedTmMeetingTypeSet] = useState<string | undefined>();
  useEffect(() => {
    console.log("---" + selectedTmMeetingType);
  }, [selectedTmMeetingType]);

  const [dataSelectedOtherTeamMembers, dataSelectedOtherTeamMembersSet] = useState<UserProfile[]>([]);

  const [selectedOtherInteractionServiceTreeServices, selectedOtherInteractionServiceTreeServicesSet] = useState<ServiceTreeService[]>([]);

  const serviceChange = (selectedService: ServiceTreeService) => { dataSelectedServiceTreeServiceSet(selectedService) };


  /*Ad hoc reschedule*/
  const tomorrow = addDays(today, 1);
  const [reviewTimeZone, reviewTimeZoneSet] = useState<TMPTimeZone>();
  const [reviewTime, reviewTimeSet] = useState<MeetingTime>();
  const [selectedAdhocRescheduleDate, selectedAdhocRescheduleDateSet] = useState<Date | undefined>(tomorrow);
  const [is30MinSlot, is30MinSlotSet] = useState<boolean>(false);
  const [is1hrMinSlot, is1hrMinSlotSet] = useState<boolean>(true);
  const on1hrSlotCheck = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      is1hrMinSlotSet(checked as boolean);
    },
    [],
  );
  const on30MinSlotCheck = useCallback(
    (ev?: React.FormEvent<HTMLElement | HTMLInputElement>, checked?: boolean): void => {
      is30MinSlotSet(checked as boolean);
    },
    [],
  );
  useEffect(() => {
    is1hrMinSlotSet(!is30MinSlot);
  }, [is30MinSlot]);
  useEffect(() => {
    is30MinSlotSet(!is1hrMinSlot);
  }, [is1hrMinSlot]);
  const onAdhocRescheduleFooterContent = () => (
    <Stack horizontal tokens={stackTokens}>
      <SpinnerPrimaryButton text="Save" onclick={adhocReschedule} executingText="Rescheduling the meeting to adhoc timing." />
      <DefaultButton onClick={hideAdhocRescheduleEditModal}>Cancel</DefaultButton>
    </Stack>
  );
  const adhocReschedule = async () => {
    if (selectedAdhocRescheduleDate) {

      if (reviewId === undefined || serviceId === undefined) {
        displayAdhocReschedulePanelMessagesSet([{ message: "Could not determine review parameters ReviewId or ServiceId", messageType: DisplayMessageType.Error }]);
        return;
      }

      if (reviewTimeZone === undefined || reviewTimeZone.id === undefined || reviewTimeZone.id.length === 0) {
        displayAdhocReschedulePanelMessagesSet([{ message: "Please select Timezone", messageType: DisplayMessageType.Warning }]);
        return;
      }

      if (reviewTime === undefined || reviewTime.id === undefined || reviewTime.id.length === 0) {
        displayAdhocReschedulePanelMessagesSet([{ message: "Please select Time of meeting", messageType: DisplayMessageType.Warning }]);
        return;
      }

      var d = new Date(selectedAdhocRescheduleDate);
      var rescheduleAdhoc: RescheduleAdhoc = {
        reviewId: reviewId,
        serviceId: serviceId,
        dateOfReview: selectedAdhocRescheduleDate,
        is1HourMeeting: is1hrMinSlot,
        is30MinMeeting: is30MinSlot,
        timeOfMeeting: reviewTime,
        timeZoneOfMeeting: reviewTimeZone,
        clientOffsetMinutes: d.getTimezoneOffset(),
      };

      var response = await reviewService.rescheduleToAdhocMeeting(rescheduleAdhoc);
      if (response && !response.hasErrors && response.result) {
        dataReviewRawSet(response.result);
        hideAdhocRescheduleEditModal();
        displayMessagesSet([{ message: "Successfully rescheduled the review", messageType: DisplayMessageType.Success }]);
      }
      else {
        if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
          displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        }
        else {
          displayMessagesSet([{ message: "Could not reschedule the review.", messageType: DisplayMessageType.Error }]);
        }
      }
    }
    else {
      displayAdhocReschedulePanelMessagesSet([{ message: "Please select a date of review", messageType: DisplayMessageType.Warning }]);
      return;
    }
  }

  const [isFetchingForDownload, isFetchingForDownloadSet] = useState<boolean>();
  const onArtifactClick = async (artifact: Artifact) => {
    if (artifact.artifactType == ArtifactTypeStrings.HyperLink) {
       displayMessagesSet([{ message: "The artifact downloaded.", messageType: DisplayMessageType.Success }]);
    }
    if (artifact.artifactType == ArtifactTypeStrings.File) {
      isFetchingForDownloadSet(true);
      if (artifact.artifactId && artifact.serviceId && reviewId) {
        var response = await artifactService.getArtifactSasUrlForReview(artifact.artifactId, artifact.serviceId, reviewId);
        if (response && !response.hasErrors && response.result && response.result.length > 0) {
            displayMessagesSet([{ message: "The artifact is downloaded.", messageType: DisplayMessageType.Success }]);
        }
        else {
          if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
            displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Warning }]);
          }
          else {
            displayMessagesSet([{ message: "The artifact could not be downloaded.", messageType: DisplayMessageType.Error }]);
          }
        }
      }

      isFetchingForDownloadSet(false);
    }
  }


  const [isFollowUpAnswerEditModalOpen, { setTrue: showFollowUpEditModal, setFalse: hideFollowUpEditModal }] = useBoolean(false);
  const [currentFollowup, currentFollowupSet] = useState<FollowUps>();


  const onFollowUpEditModalFooterContent = () => (
    <Stack horizontal tokens={stackTokens}>
      <SpinnerPrimaryButton text="Save" onclick={respondToFollowUp} executingText="Saving the response." />
      <DefaultButton onClick={hideFollowUpEditModal}>Cancel</DefaultButton>
    </Stack>
  );
  const respondToFollowUp = async () => {
    if (currentFollowup && currentFollowup.id && currentFollowup.id.length > 0 && currentFollowup.answer && currentFollowup.answer.length > 0) {
      var response = await reviewService.addFollowUp(reviewId, serviceId, currentFollowup.id, currentFollowup.answer, dataReview?.eTag);
      if (response && !response.hasErrors && response.result) {
        dataReviewRawSet(response.result);
        hideFollowUpEditModal();
      }
      else {
        if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
          displayMessagesSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        }
        else {
          displayMessagesSet([{ message: "The followup could not be updated", messageType: DisplayMessageType.Error }]);
        }
      }
    }

  }

  const [reviewFeedback, reviewFeedbackSet] = useState<DraftFeedback[]>([]);
  useEffect(() => {
    if (reviewFeedback && reviewFeedback.length > 0) {
      var currentUserFeedbacks = reviewFeedback.filter(x => x.reviewerUpn === appState?.userProfile?.userPrincipalName);
      if (currentUserFeedbacks && currentUserFeedbacks.length > 0) {
        if (currentUserFeedbacks[0].internalNotes && currentUserFeedbacks[0].internalNotes.length > 0) {
          setContentEditor(currentUserFeedbacks[0].internalNotes)
        }

        // if (currentUserFeedbacks[0].draftTasksAndBugs && currentUserFeedbacks[0].draftTasksAndBugs.length > 0) {
        //     currenUserDraftDataWorkItemsSet(currentUserFeedbacks[0].draftTasksAndBugs);
        // }

        // if (currentUserFeedbacks[0].followupsToServiceTeam && currentUserFeedbacks[0].followupsToServiceTeam.length > 0) {
        //     currenUserDraftDataWorkItemsSet(currentUserFeedbacks[0].followupsToServiceTeam);
        // }
      }

      let twkItems: ReviewWorkItem[] = [];
      reviewFeedback.forEach(r => {
        if (r.draftTasksAndBugs && r.draftTasksAndBugs.length > 0) {
          r.draftTasksAndBugs.forEach(s => {
            twkItems.push(s);
          });
        }
      });
      draftDataWorkItemsSet(twkItems);

      let tfollowsToServiceTeam: FollowUps[] = [];
      reviewFeedback.forEach(f => {
        if (f.followupsToServiceTeam && f.followupsToServiceTeam.length > 0) {
          f.followupsToServiceTeam.forEach(fs => {
            tfollowsToServiceTeam.push(fs);
          });
        }
      })
      followUpToServiceTeamSet(tfollowsToServiceTeam);
    }
  }, [reviewFeedback])

  const saveFeedBackNoRefresh = async (): Promise<DraftFeedback[] | undefined> => {
    console.log("saving without refresh");
    let feedback: DraftFeedback = {
      reviewId: reviewId,
      serviceId: serviceId,
    };

    if (contentEditor && contentEditor.length > 0) {
      feedback.internalNotes = contentEditor;
    }

    var userFeedback = reviewFeedback.filter(x => x.reviewerUpn === appState.userProfile?.userPrincipalName);

    // draftDataWorkItems newly created work items. feedback will have all previously crated work items. we merge both and replace in the draft.
    if (draftDataWorkItems && draftDataWorkItems.length > 0) {
      feedback.draftTasksAndBugs = draftDataWorkItems.filter(x => x.createdBy === appState?.userProfile?.userPrincipalName);
    }

    if (followUpToServiceTeam && followUpToServiceTeam.length > 0) {
      var newFollowUps = followUpToServiceTeam.filter(x => x.createdBy === appState?.userProfile?.userPrincipalName);
      feedback.followupsToServiceTeam = newFollowUps;
    }

    var fbResult = await reviewService.saveFeedback(feedback);
    if (fbResult && !fbResult.hasErrors && fbResult.result && fbResult.result.length > 0) {
      return fbResult.result;
    }
    else {
      if (fbResult && fbResult.hasErrors && fbResult.businessErrors && fbResult.businessErrors.length > 0 && fbResult.businessErrors[0].message) {
        displayMessagesSet([{ message: fbResult.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
      }

    }
  }
  const autoSaveFeedBack = async () => {
    var fbResult = await saveFeedBackNoRefresh();
    if (fbResult && fbResult.length > 0) {
      reviewFeedbackSet(fbResult);
    }
    else {

    }
  }
  const loadReviewFeedback = async (reviewId?: string, serviceId?: string): Promise<DraftFeedback[] | undefined> => {
    if (reviewId && serviceId) {
      var fbResult = await reviewService.getReviewersFeedback(reviewId, serviceId);
      if (fbResult && !fbResult.hasErrors && fbResult.result && fbResult.result.length > 0) {
        return fbResult.result;
      }
      else {

      }
    }
  }
  const autoRefreshFeedBack = async () => {
    if (reviewId && serviceId) {
      var fbResult = await reviewService.getReviewersFeedback(reviewId, serviceId);
      if (fbResult && !fbResult.hasErrors && fbResult.result && fbResult.result.length > 0) {
        var result = await loadReviewFeedback(reviewId, serviceId);
        if (result && result.length > 0) {
          reviewFeedbackSet(result);
        }
      }
      else {

      }
    }
  }

  const [tasksAndBugsForPublish, tasksAndBugsForPublishSet] = useState<ReviewWorkItem[]>([]);
  const onPublishTasksAndBugs = async () => {
    displayDraftTasksAndBugsErrorsSet([]);
    if (tasksAndBugsForPublish && tasksAndBugsForPublish.length > 0) {
      var hasEmptyFields = tasksAndBugsForPublish.some(x => isEmptyOrSpaces(x.type)
        || x.riskRating === undefined
        || isEmptyOrSpaces(x.weaknessType)
        || isEmptyOrSpaces(x.title)
        || isEmptyOrSpaces(x.description));

      if (hasEmptyFields) {
        displayDraftTasksAndBugsErrorsSet([{ message: "Atleast one of the selected task or bug has a mandatory field empty. Ensure Type, Risk Rating, Weakness Type, Title and Description are all filled.", messageType: DisplayMessageType.Warning }]);
        return;
      }

      let data: PublishTasksAndBugs = {
        reviewId: reviewId,
        serviceId: serviceId,
        eTag: dataReview?.eTag,
        tasksAndBugsTobePublished: tasksAndBugsForPublish
      };

      var response = await reviewService.publishTasksAndBugs(data);
      if (response && !response.hasErrors && response.result) {
        dataReviewRawSet(response.result);
      }
      else {
        if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
          displayDraftTasksAndBugsErrorsSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        }
        else {
          displayDraftTasksAndBugsErrorsSet([{ message: "The publish failed.", messageType: DisplayMessageType.Error }]);
        }
      }
    }
    else {
      displayDraftTasksAndBugsErrorsSet([{ message: "Please select tasks or bugs for publishing", messageType: DisplayMessageType.Warning }]);
    }
  }
  const [followupsForPublish, followupsForPublishSet] = useState<FollowUps[]>([]);
  const onPublishFollowups = async () => {
    if (followupsForPublish && followupsForPublish.length > 0) {
      let data: PublishFollowups = {
        reviewId: reviewId,
        serviceId: serviceId,
        eTag: dataReview?.eTag,
        followupsTobePublished: followupsForPublish,
      };

      var response = await reviewService.publishFollowups(data);
      if (response && !response.hasErrors && response.result) {
        dataReviewRawSet(response.result);
      }
      else {
        if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
          displayDraftFolloupErrorsSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
        }
        else {
          displayDraftFolloupErrorsSet([{ message: "The publish failed.", messageType: DisplayMessageType.Error }]);
        }
      }
    }
    else {
      displayDraftFolloupErrorsSet([{ message: "Please select followups for publishing", messageType: DisplayMessageType.Warning }]);
    }
  }

  // Start of changes for panel footer of isFututureDatedReviewModal
  // add a checkbox here and once selected it should set the  isFDRProceedClickedSet to true

  const onRenderFDFooterContent = () => (

    <div className="row"> <text>Proceeding to Update the state of this review to Completed will Clear the Calender Invite & Free-up the slot, for other users to book. What do you want to Do?</text><div><br /></div>
      < Checkbox label="I want to proceed with the update" checked={isFDRProceedClicked} onChange={(ev, checked) => isFDRProceedClickedSet(checked === true)} /><div><br /></div>
      <Stack horizontal tokens={stackTokens}>
        <SpinnerPrimaryButton text="Proceed" onclick={proceedfdreview} executingText="Saving The Review" />
        <DefaultButton onClick={() => {
          isFDRProceedClickedSet(false);
          hideisFutureDateReviewModal();

        }}>Cancel</DefaultButton>
      </Stack>
    </div>
  );
  const proceedfdreview = async () => {
    if (isFDRProceedClicked) {
      await onReviewSaveClick();
      hideisFutureDateReviewModal();
      return;
    }
    else {
      displayFDReviewPanelMessagesSet([{ message: "Please select the checkbox to proceed", messageType: DisplayMessageType.Error }]);
      return;
    }

  }

  // End of changes for panel footer of isFututureDatedReviewModal

  return (
    <>
      <div className="row">
        <MessageComponent messages={displayMessages} />
      </div>

      {isReviewFound &&
        <div className="row">

          <Pivot>
            <PivotItem headerText="Review Details" itemIcon="AccountManagement" itemKey="0" >
              <div className="row" style={{ margin: 0, padding: 0 }}>
                <div className="d-flex" style={{ margin: 0, padding: 0 }}>
                  <div className="me-auto" style={{ marginLeft: 0 }}>
                    <HeadingComponent title="Review Details" subtitle="" />
                  </div>
                  {
                    dataReview && dataReview.isReviewEditable &&
                    <Stack horizontal tokens={stackTokens}>
                      {!dataReview.isV1Record && (
                        <Stack horizontal tokens={stackTokens} tabIndex={0} style={{ cursor: 'pointer', color: 'blue' }}>
                          <Link to={""} onClick={() => { reset(); showReviewDetailEditModal(); }} style={{ textDecoration: "none" }}>
                            <FontIcon iconName="Edit" title="Edit" />
                            <Text variant="medium">Edit</Text>
                          </Link>
                        </Stack>)}
                      {
                        (dataReview &&
                          dataReview.meetingsData &&
                          dataReview.meetingsData.browserLocalTime &&
                          new Date(dataReview.meetingsData.browserLocalTime) > new Date()) &&
                        <Stack horizontal tokens={stackTokens} tabIndex={0} style={{ cursor: 'pointer', color: 'blue' }}>
                          <Link to={""} onClick={() => { reset(); showDeleteModal(); }} style={{ textDecoration: "none" }}>
                            <FontIcon iconName="Delete" title="delete" />
                            <Text variant="medium">Cancel</Text>
                          </Link>
                        </Stack>
                      }

                    </Stack>
                  }
                </div>
              </div>
              <div className="row">

                <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                  <div className="col-4">
                    <div className="row"> <Label>Requestor </Label></div>
                    <div className="row">
                      <Persona style={{ marginTop: 20, marginBottom: 10 }}
                        {...requestorPersona}
                        size={PersonaSize.size32}
                      /></div>
                  </div>

                  <div className="col-4">
                    <div className="row"> <Label>Service Name </Label></div>
                    <div className="row">
                      <Stack horizontal tokens={stackTokens}>
                        <Text id="review_detail_serviceName">{dataReview?.service?.serviceName} </Text>
                        <CopyToClipboard text={dataReview?.service?.serviceName} />
                      </Stack>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="row"> <Label>Service Id</Label></div>
                    <div className="row"> <Stack horizontal tokens={stackTokens}> <Text>{dataReview?.service?.serviceId} </Text> <CopyToClipboard text={dataReview?.service?.serviceId} /> </Stack></div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                  <div className="col-4">
                    <div className="row"> <Label>Service Priority </Label></div>
                    <div className="row"> <Text>{dataReview?.service?.sdlPrioritization} </Text></div>
                  </div>

                  <div className="col-4" >
                    <div className="row"> <Label>Review Id </Label></div>
                    <div className="row">
                      <Stack horizontal tokens={stackTokens}>
                        <Text id="review_detail_easyreviewId">{dataReview?.easyReviewId} </Text>
                        <CopyToClipboard text={dataReview?.easyReviewId} />
                      </Stack>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="row"> <Label>Meeting Type</Label></div>
                    <div className="row"> <Text>{dataReview?.reviewType} </Text></div>
                  </div>
                </div>

                <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                  <div className="col-4" >
                    <div className="row"> <Label>Review Team </Label></div>
                    <div className="row"> <Text>{dataReview?.securityReviewTeam?.name}</Text></div>
                  </div>

                  <div className="col-4">
                    <div className="row"> <Label>Other members </Label> </div>
                    <div className="row">
                      <Stack horizontal>
                        {
                          otherTeamMembers.sort((a, b) => a.text!.localeCompare(b.text!)).map(personProps => {
                            return <StackItem key={"s_" + personProps.key}>
                              <Persona
                                style={{ marginTop: 20, marginBottom: 10 }}
                                {...personProps}
                                key={"p_" + personProps.key}
                                title={personProps.text}
                                hidePersonaDetails={true}
                                size={PersonaSize.size32}
                              />
                            </StackItem>
                          })
                        }
                      </Stack>
                    </div>
                  </div>

                  <div className="col-4">
                    <div className="row">
                      <div className="row"> <Label>Status </Label></div>
                      <div className="row"> <Text>{dataReview?.reviewStatus}</Text></div>

                    </div>
                  </div>
                </div>

                {/* <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                                <div className="col-4" >
                                </div>

                                <div className="col-4" >
                                    
                                </div>

                                <div className="col-4" >
                                </div>

                            </div> */}

              </div>

              <hr />
              <div className="row">
                <div className="d-flex" style={{ margin: 0, padding: 0 }}>
                  <div className="me-auto" style={{ marginLeft: 0 }}>
                    <HeadingComponent title="Meeting Details" subtitle="" />
                  </div>
                  <Stack horizontal tokens={stackTokens}>
                    {
                      dataReview && dataReview.reviewRecordType && dataReview.reviewRecordType == ReviewRecordStrings.ScheduledMeeting && dataReview.isReviewEditable && !dataReview.isV1Record &&
                      <Stack horizontal
                        tokens={stackTokens}
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => { reset(); showMeetingDetailEditModal(); }}>
                        <FontIcon iconName="MailSchedule" title="Reschedule" />
                        <Text variant="medium" color="blue">Reschedule</Text>
                      </Stack>
                    }
                    {
                      dataReview && dataReview.isReviewerForReview && dataReview.securityReviewTeam && dataReview.securityReviewTeam.id && dataReview.isReviewEditable && !dataReview.isV1Record &&
                      <Stack horizontal tokens={stackTokens}
                        style={{ cursor: 'pointer', color: 'blue' }}
                        onClick={() => { reset(); showAdhocRescheduleEditModal(); }}>
                        <FontIcon iconName="ScheduleEventAction" title="Reschedule to adhoc slot" />
                        <Text variant="medium" color="blue">Adhoc Meeting</Text>
                      </Stack>
                    }

                  </Stack>
                </div>
              </div>

              <div className="row">
                <div className="col">
                  <Label>Date Time</Label>
                  <Stack horizontal tokens={stackTokens}>
                    <StackItem>
                      {getDateFormat(dataReview?.meetingsData?.browserLocalTime) + '-' + getTimeFormat(dataReview?.meetingsData?.browserLocalTime)}
                    </StackItem>
                    <StackItem>
                      <MeetingTimeCalloutComponent meetingData={dataReview?.meetingsData} />
                    </StackItem>
                  </Stack>
                </div>
              </div>

              {
                dataReview && dataReview.additionalAdhocMeetings && dataReview.additionalAdhocMeetings.length > 0 &&
                <div className="row">
                  <div className="me-auto" style={{ marginLeft: 0 }}>
                    <Label title="Additional Meeting(s) "> Additional Meeting(s) </Label>
                  </div>

                  {
                    dataReview.additionalAdhocMeetings.map(x => {
                      return <div className="row" key={"additionalmeetings_" + x.clientReferenceId}>
                        <Stack horizontal tokens={stackTokens}>
                          <StackItem>
                            {getDateFormat(x?.browserLocalTime) + '-' + getTimeFormat(x?.browserLocalTime)}
                          </StackItem>
                          <StackItem>
                            <MeetingTimeCalloutComponent meetingData={x} />
                          </StackItem>
                        </Stack>
                      </div>
                    })
                  }

                </div>
              }

              {
                <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                  <div className="col-4"></div>
                </div>
              }

              <hr />

              <div className="row">
                <div className="d-flex">
                  <div className="me-auto" style={{ marginLeft: 0 }}>
                    <HeadingComponent title="Artifact Details" subtitle="" />
                  </div>

                  <div>
                    {reviewId && serviceId && dataReview && dataReview.service && dataReview.service.serviceName && dataReview.isReviewEditable &&
                      <UploadOrLinkArtifactsComponent
                        reviewId={reviewId}
                        serviceId={serviceId}
                        serviceName={dataReview?.service?.serviceName}
                        onArtifactUploaded={(uploadedArtifacts: LinkedArtifact[]) => {
                          if (uploadedArtifacts && uploadedArtifacts.length > 0) {
                            loadArtifactsForDownload(uploadedArtifacts);
                          }
                        }}
                        onArtifactLinked={(linkedArtifacts: LinkedArtifact[]) => {
                          console.log("On Artifacts linked");
                          if (linkedArtifacts && linkedArtifacts.length > 0) {
                            loadArtifactsForDownload(linkedArtifacts);
                          }
                        }}
                      />
                    }
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginTop: 10, marginBottom: 10 }}>
                <div className="row">
                  <div className="col">
                    {
                      isNoArtifacts && <div className="row">
                        <Text>No Artifact(s) found.</Text>
                      </div>
                    }
                    {
                      isFetchingForDownload && <Spinner size={SpinnerSize.small} label="loading file for download" />
                    }
                    <Stack>
                      {
                        dataDownloadableArtifacts && dataDownloadableArtifacts.length > 0 &&
                        dataDownloadableArtifacts.map(artifact => {
                          return <StackItem key={artifact.artifactId}>
                            <div className="d-flex">
                              <span style={{ marginTop: 5, marginRight: 10 }}>
                                <FileTypeLogoComponent
                                  artifactType={artifact.artifactType as string}
                                  fileName={artifact.fileName}
                                />
                              </span>
                              <Label className="me-auto">{artifact.artifactTitle}</Label>
                              {/*Added new Component to display date of artifact and who uploaded the artifact*/}
                              <ArtifactInfoComponent uploadby={artifact.uploadedBy} uploadon={getDateFormat(artifact.uploadedOn) + ' ' + getTimeFormat(artifact.uploadedOn)}></ArtifactInfoComponent>
                               <FontIcon iconName="CloudDownload" title={artifact.artifactUrl} style={{ color: 'blue', cursor: 'pointer',marginLeft:"330px"}} onClick={
                                (e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  onArtifactClick(artifact);
                                }
                              } />
                            </div>
                          </StackItem>
                        })
                      }
                    </Stack>
                  </div>
                  <div className="col">
                  </div>
                </div>
                <div className="row">
                  <div className="col">
                    {
                      isFetchingDownloadableArtifacts &&
                      <Spinner title="Refreshing artifacts for download" label="Refreshing artifacts for download" size={SpinnerSize.small} />
                    }
                  </div>
                  <div className="col"></div>
                </div>
              </div>

              <hr />
              <div className="row">
                <HeadingComponent title="Follow up(s) from Review Team " subtitle="The review team is requesting more information as below." />
              </div>
              <div className="row">
                {
                  dataReview && dataReview.publishedFollowUps && dataReview.publishedFollowUps.length > 0 ? <div>
                    {
                      dataReview.publishedFollowUps.map(f => {
                        return <div className="row" style={{ marginTop: 10 }} key={"publishedFollowups_" + f.id}>
                          {
                            f.question && f.question.length > 0 &&
                            <div className="col">
                              <div className="d-flex">
                                <Text>{f.question}</Text>
                                {dataReview.isReviewEditable &&
                                  <Stack tokens={stackTokens} horizontal style={{ marginLeft: 10 }}>
                                    <FontIcon iconName="EditNote" onClick={() => { showFollowUpEditModal(); currentFollowupSet(f); }} title={"respond"} className="ms-auto" style={{ fontSize: 14, color: "blue", cursor: 'pointer' }} />
                                  </Stack>
                                }
                              </div>
                            </div>
                          }
                          {
                            f.answer && f.answer.length > 0 &&
                            <div className="col">
                              <Editor
                                init={{
                                  skin: false,
                                  content_css: false,
                                  height: 100,
                                  menubar: false,
                                  statusbar: false,
                                  toolbar: false,
                                }}
                                value={f.answer}
                                disabled={true} />
                            </div>
                          }
                        </div>
                      })
                    }
                  </div>
                    : <Text>No followups requested by review team</Text>
                }
              </div>
              <hr />
              <div className="row">
                <div className="row">
                  <HeadingComponent title="Notes from Review Team" subtitle="" />
                  {
                    appState.userMetadata && appState.userMetadata.isReviewer &&
                    <Text>As a reviewer you can add message to service team in Reviewer Pane</Text>
                  }
                </div>
                <div className="row">
                  {/* <Text>{dataReview?.notesToRequestor}</Text> */}
                  <Editor
                    init={{
                      skin: false,
                      content_css: false,
                      height: 300,
                      menubar: false,
                      statusbar: false,
                      toolbar: false,
                      table_default_attributes: {
                        border: '1'
                      },

                    }}
                    value={dataReview?.notesToRequestor ? dataReview?.notesToRequestor : ""}
                    disabled={true}
                  />
                </div>
                {
                  appState.userMetadata && (appState.userMetadata.isReviewer || appState.userMetadata.isAdmin) &&
                  <div className="row">
                    <InternalNotesComponent reviewerNotes={dataReview?.reviewerNotes} userProfiles={reviewerProfiles} />
                  </div>
                }
              </div>
              <hr />
              <div className="row">
                <HeadingComponent title="Tasks and Bugs" subtitle="The list of tasks and bugs captured by the review team." />
              </div>
              <div className="row">
                {
                  dataReview && dataReview.tmReviewActions && dataReview.tmReviewActions.length > 0 &&
                  <ReviewTasksnBugsComponent
                    workItems={dataReview.tmReviewActions} />
                }
              </div>

            </PivotItem>
            <PivotItem headerText="Service Snapshot" itemIcon="FullHistory" itemKey="1">
              <ServiceReviewHistoryComponent
                serviceId={dataReview?.service?.serviceId}
                serviceName={dataReview?.service?.serviceName} />
            </PivotItem>
            {dataReview && dataReview?.questions && dataReview?.questions.length > 0 && (
              <PivotItem headerText="Questionnaire Responses" itemIcon="SurveyQuestions" itemKey="2" >
                <div className="row">
                  <div className="d-flex" style={{ padding: 0 }}>
                    <HeadingComponent title="Questionnaire Responses" subtitle="" />
                  </div>
                  <div className="row">
                    <PreviewQuestionnaire isDesign={false} isPreview={true} isUserView={true} questionsList={dataReview.questions} />
                  </div>
                </div>
              </PivotItem>
            )}
            {
              dataReview && dataReview.isReviewerForReview &&
              <PivotItem headerText="Reviewer Pane" itemIcon="AccountManagement" itemKey="3" >

                <div className="row" >

                  <div className="row" style={{ margin: 5 }}>
                    <Stack horizontal tokens={stackTokens} className="me-auto">
                      <Label>Service Name :</Label>
                      <Text style={{ marginTop: 5 }} id="review_detail_reviewer_serviceName">{dataReview?.service?.serviceName} </Text>
                      <Label>Review Id :</Label>
                      <Text style={{ marginTop: 5 }} id="review_detail_reviewer_reviewId">{dataReview?.easyReviewId} </Text>
                      <Label>Review Type :</Label>
                      <Text style={{ marginTop: 5 }} id="review_detail_reviewer_reviewType">{dataReview?.reviewType} </Text>
                    </Stack>

                  </div>

                  <Pivot>
                    <PivotItem headerText="Scribble pad">
                      <div className="row">
                        <div className="d-flex">
                          <HeadingComponent title="Internal Notes" subtitle="This section is private to review team." />
                          <div className="ms-auto">
                            {
                              dataReview.reviewStatus !== ReviewStatusStrings.Completed &&
                              <Stack horizontal tokens={stackTokens}>
                                <CountdownTimer
                                  callback={async () => {
                                    console.log('timer in scribblepad')
                                    await autoSaveFeedBack();
                                  }}
                                  callbackExecutingText="Saving"
                                  coundowntext="Auto save in"
                                  label="Auto save"
                                  seconds={120} />
                                <Stack>
                                  <SpinnerPrimaryButton text="save" executingText="saving.."
                                    onclick={async () => {
                                      console.log('Save clicked in scribble pad')
                                      await autoSaveFeedBack();
                                    }} />
                                  <Text variant="small" >(hit save before leaving the tab)</Text>
                                  <Text variant="small" >(Only Assigned Reviewers can save the Notes)</Text>
                                </Stack>
                              </Stack>
                            }
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <Label>Internal Review Notes</Label>
                          <Editor
                            onInit={(evt, editor) => editorRef.current = editor}
                            init={{
                              skin: false,
                              content_css: false,
                              height: 500,
                              menubar: false,
                              statusbar: false,
                              paste_data_images: true,
                              plugins: [
                                'link image lists advlist table paste charmap fullscreen hr'
                              ],
                              toolbar1:
                                'undo redo | styleselect | fontsizeselect fontselect | bold italic underline| \
                                             alignleft aligncenter alignright alignjustify | forecolor backcolor',
                              toolbar2: 'bullist numlist outdent indent | removeformat \
                                                   table link image charmap | fullscreen hr | superscript subscript| hyperlinkButton' ,
                              table_default_attributes: {
                                border: '1'
                              },

                            }}
                            value={contentEditor}
                            onEditorChange={handleEditorChange}
                            disabled={dataReview.reviewStatus === ReviewStatusStrings.Completed}
                          />
                        </div>
                        <div className="col">
                          <div className="row">
                            <div className="col">
                              <Label>Draft Follow ups</Label>
                              <Text variant="xSmall">(Saved to draft followups automatically)</Text>
                              {dataReview.reviewStatus !== ReviewStatusStrings.Completed &&
                                <TextField
                                  value={followUp}
                                  placeholder="Press enter to create item"
                                  onChange={onFollowUpChange}
                                  iconProps={{ iconName: 'Add', style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => { addFollowUp(followUp) } }}
                                  onKeyDown={(e) => { if (e.key == 'Enter') { addFollowUp(followUp) } }} />
                              }
                              <ul style={{ listStyleType: "none", padding: 0 }}>
                                {
                                  currentUserFollowUpToServiceTeam && currentUserFollowUpToServiceTeam.length > 0 &&
                                  currentUserFollowUpToServiceTeam.map(x => {
                                    return <li key={"user_f_li_" + x.id} style={{ margin: 2, width: 'auto' }}>
                                      <div className="d-flex">
                                        <Text block={false} variant="smallPlus" title={x.question} style={{ marginLeft: 5 }}>{x.question}</Text>
                                      </div>
                                    </li>
                                  })
                                }
                              </ul>
                            </div>
                            <div className="col">
                              <Label>Draft Action Items</Label>
                              <Text variant="xSmall">(Saved to draft bugs automatically)</Text>
                              {
                                dataReview.reviewStatus !== ReviewStatusStrings.Completed &&
                                <TextField
                                  value={actionItem}
                                  placeholder="Press enter to create item"
                                  onChange={onActionItemChange}
                                  iconProps={{ iconName: 'Add', style: { pointerEvents: "auto", cursor: "pointer" }, onClick: () => { addActionItem(actionItem) } }}
                                  onKeyDown={(e) => { if (e.key == 'Enter') { addActionItem(actionItem) } }} />
                              }
                              <ul style={{ listStyleType: "none", padding: 0 }}>
                                {
                                  currentUserDraftDataWorkItems && currentUserDraftDataWorkItems.length > 0 &&
                                  currentUserDraftDataWorkItems.map(x => {
                                    return <li key={"user_ac_li_" + x.referenceId} style={{ margin: 2 }}>
                                      <div className="d-flex">
                                        <Text block={false} variant="smallPlus" title={x.title} style={{ marginLeft: 5 }}>{x.title}</Text>
                                      </div>
                                    </li>
                                  })
                                }
                              </ul>

                            </div>
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row">
                        <div className="row">
                          <HeadingComponent title="Notes and followups from other reviewers" />
                        </div>
                        <div className="row">
                          <OtherUserScribbleComponent
                            feedback={reviewFeedback}
                            reviewerProfiles={reviewerProfiles}
                            onActionOrFollowUpQuickClick={(text?: string) => {
                            }}
                          />
                        </div>
                      </div>
                      <div className="row" style={{ height: 300 }}></div>
                    </PivotItem>
                    <PivotItem headerText="Followups">
                      <div className="row">
                        <div className="row">
                          <div className="d-flex">
                            <HeadingComponent title="Follow up(s) to the Service Team" subtitle="Put your questions here that you want service team to answer." />

                          </div>
                        </div>
                        {
                          dataReview.reviewStatus !== ReviewStatusStrings.Completed &&
                          <div className="row">
                            <div className="col">
                              <FollowUpCreateComponent
                                selectedFollowupItem={followupItemForEdit}
                                onFollowupToServiceTeamCreated={(ftoserviceteam) => {
                                  if (ftoserviceteam) {
                                    var remainingItems = followUpToServiceTeam.filter(x => x.id !== ftoserviceteam.id)
                                    if (remainingItems && remainingItems.length > 0) {
                                      followUpToServiceTeamSet([...remainingItems, ftoserviceteam]);
                                    }
                                    else {
                                      followUpToServiceTeamSet([ftoserviceteam]);
                                    }
                                  }
                                  followupItemForEditSet(undefined);
                                }} />

                            </div>
                          </div>
                        }
                      </div>

                      <div className="row" style={{ marginTop: 30 }}>
                        <div className="row">
                          <div className="d-flex">
                            <HeadingComponent title="Draft followups to service team" />
                            {
                              dataReview.reviewStatus !== ReviewStatusStrings.Completed &&
                              <Stack className="ms-auto" horizontal tokens={stackTokens}>
                                <CountdownTimer
                                  callback={async () => {
                                    console.log('Timer in followups')
                                    await autoSaveFeedBack();
                                  }}
                                  callbackExecutingText="Refreshing"
                                  coundowntext="Auto save in"
                                  label="Auto save"
                                  seconds={120} />
                                <SpinnerPrimaryButton text="save and refresh" executingText="saving to drafts" onclick={async () => {
                                  console.log('Save clicked in follow ups')
                                  await autoSaveFeedBack();
                                }} />
                                <SpinnerPrimaryButton text="Publish selected followups" executingText="Publishing followups"
                                  onclick={async () => {
                                    await autoSaveFeedBack();
                                    await onPublishFollowups();
                                    console.log("Refresh feedback on publish followups")
                                    await autoRefreshFeedBack();
                                  }} />
                              </Stack>
                            }
                          </div>
                        </div>

                        <div className="row">
                          <div className="row">
                            <MessageComponent messages={displayDraftFolloupErrors} />
                          </div>
                          <div className="row">
                            <ReviewerDraftFollowUpsComponent
                              items={followUpToServiceTeam}
                              reviewerProfiles={reviewerProfiles}
                              onFollowUpsSelected={(followUpsToPublish) => {
                                if (followUpsToPublish && followUpsToPublish.length > 0) {
                                  followupsForPublishSet(followUpsToPublish);
                                }
                                else {
                                  followupsForPublishSet([]);
                                }
                              }}
                              onFollowupEditClick={(eFollowup) => {
                                if (eFollowup) {
                                  if (eFollowup.createdBy == appState?.userProfile?.userPrincipalName) {
                                    var remaininItems = followUpToServiceTeam.filter(x => x.id !== eFollowup.id);
                                    if (remaininItems && remaininItems.length > 0) {
                                      followUpToServiceTeamSet(remaininItems);
                                    }
                                    else {
                                      followUpToServiceTeamSet([]);
                                    }
                                  }
                                  else {
                                    // if you are modifying some one else task or bug. chang uuid so that the bug gets cloned by you.
                                    console.log('created by others');
                                  }

                                  followupItemForEditSet(eFollowup);
                                }
                              }}
                              onFollowupDeleteClick={(dFollowup) => {
                                if (dFollowup) {
                                  if (dFollowup.createdBy == appState?.userProfile?.userPrincipalName) {
                                    var remaininItems = followUpToServiceTeam.filter(x => x.id !== dFollowup.id);
                                    if (remaininItems && remaininItems.length > 0) {
                                      followUpToServiceTeamSet(remaininItems);
                                    }
                                    else {
                                      followUpToServiceTeamSet([]);
                                    }
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row">
                        <div className="row">
                          <HeadingComponent title="Published followups" />
                        </div>
                        <div className="row">
                          {
                            dataReview.publishedFollowUps && dataReview.publishedFollowUps.length > 0 ?
                              dataReview.publishedFollowUps.map(af =>
                                <div className="row" key={"reviewer_publishedFollowups_" + af.id}>
                                  {
                                    af.question && af.question.length > 0 &&
                                    <div className="col">
                                      <Text>{af.question}</Text>
                                    </div>
                                  }
                                  {
                                    af.answer && af.answer.length > 0 &&
                                    <div className="col">
                                      <Editor
                                        init={{
                                          skin: false,
                                          content_css: false,
                                          height: 50,
                                          menubar: false,
                                          statusbar: false,
                                          toolbar: false,
                                        }}
                                        value={af.answer}
                                        disabled={true} />
                                    </div>
                                  }
                                </div>
                              )
                              : <Text>No Answered followups found.</Text>
                          }
                        </div>
                      </div>
                    </PivotItem>
                    <PivotItem headerText="Tasks and Bugs">
                      <div className="row">
                        <div className="row">
                          <div className="d-flex">
                            <Stack horizontal tokens={stackTokens}>
                              <HeadingComponent title="Draft Tasks and Bugs" />
                              <Text style={{ marginTop: 12 }}>{draftDataWorkItems?.length ?? 0}</Text>
                            </Stack>

                            <Stack horizontal tokens={stackTokens} className="ms-auto" style={{ marginRight: 30 }}>
                              <StackItem>
                                <CommandBarButton id={"btnAddbugorTask"} iconProps={{ iconName: 'Add' }} text="Add Bug or Task" onClick={() => {
                                  showTasksNBugsModalModal();
                                }} />
                                <br></br>
                                <Text variant="small" >(Only Assigned Reviewers can save the Bugs)</Text>
                              </StackItem>
                              {
                                tasksAndBugsForPublish && tasksAndBugsForPublish.length > 0 &&
                                <StackItem>
                                  <SpinnerCommandBarButton
                                    text="Publish to ADO"
                                    executingText="Publishing to ADO"
                                    iconName='WebPublish'
                                    onclick={async () => {
                                      let data: PublishTasksAndBugs = {
                                        reviewId: reviewId,
                                        serviceId: serviceId,
                                        eTag: dataReview?.eTag,
                                        tasksAndBugsTobePublished: [...tasksAndBugsForPublish]
                                      };

                                      var response = await reviewService.publishTasksAndBugs(data);
                                      if (response && !response.hasErrors && response.result) {
                                        dataReviewRawSet(response.result);
                                        displayDraftTasksAndBugsErrorsSet([{ message: "The publish was successful.", messageType: DisplayMessageType.Success }]);

                                        var remainingItems = draftDataWorkItems.filter(x => !tasksAndBugsForPublish.some(y => y.referenceId == x.referenceId))
                                        draftDataWorkItemsSet(remainingItems);
                                      }
                                      else {
                                        if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                                          displayDraftTasksAndBugsErrorsSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                                        }
                                        else {
                                          displayDraftTasksAndBugsErrorsSet([{ message: "The publish failed.", messageType: DisplayMessageType.Error }]);
                                        }
                                      }
                                    }} />
                                </StackItem>
                              }
                            </Stack>
                          </div>
                        </div>

                        <div className="row">
                          <div className="row">
                            <MessageComponent messages={displayDraftTasksAndBugsErrors} />
                          </div>
                          <div className="row">
                            <ReviewerDraftTasksAndBugsComponent
                              workItems={draftDataWorkItems}
                              reviewerProfiles={reviewerProfiles}
                              onTasksOrBugsSelected={(selectedTasksAndBugsForpublish) => {
                                if (selectedTasksAndBugsForpublish && selectedTasksAndBugsForpublish.length > 0) {
                                  tasksAndBugsForPublishSet(selectedTasksAndBugsForpublish);
                                }
                                else {
                                  tasksAndBugsForPublishSet([]);
                                }
                              }}
                              onTaskOrBugEditClick={(eTaskOrBug) => {
                                if (eTaskOrBug) {
                                  if (eTaskOrBug.createdBy == appState?.userProfile?.userPrincipalName) {
                                    console.log('created by me' + eTaskOrBug.referenceId);
                                    var remaininItems = draftDataWorkItems.filter(x => x.referenceId !== eTaskOrBug.referenceId);
                                    if (remaininItems && remaininItems.length > 0) {
                                      draftDataWorkItemsSet(remaininItems);

                                    }
                                    else {
                                      draftDataWorkItemsSet([]);
                                    }

                                  }
                                  else {
                                    // if you are modifying some one else task or bug. chang uuid so that the bug gets cloned by you.
                                    console.log('created by others');
                                  }

                                  showTasksNBugsModalModal();
                                  workItemForEditSet(eTaskOrBug);
                                }
                              }}
                              onTaskOrBugDeleteClick={async (eTaskOrBug) => {
                                if (eTaskOrBug && eTaskOrBug.referenceId && reviewId && serviceId) {
                                  var bugInArray = draftDataWorkItems.filter(x => x.referenceId === eTaskOrBug.referenceId);
                                  if (bugInArray && bugInArray.length > 0) {
                                    bugInArray[0].isDeleteInProcess = true
                                  }

                                  draftDataWorkItemsSet(draftDataWorkItems);

                                  if (eTaskOrBug.createdBy == appState?.userProfile?.userPrincipalName) {
                                    var response = await reviewService.deleteDraftWorkItem(eTaskOrBug.referenceId, reviewId, serviceId);
                                    if (response && !response.hasErrors && response.result) {
                                      var remaininItems = draftDataWorkItems.filter(x => x.referenceId !== eTaskOrBug.referenceId);
                                      if (remaininItems && remaininItems.length > 0) {
                                        draftDataWorkItemsSet(remaininItems);
                                      }
                                      else {
                                        draftDataWorkItemsSet([]);
                                      }
                                    }
                                    else {
                                      if (response && response.hasErrors && response.businessErrors[0].message) {
                                        displayDraftTasksAndBugsErrorsSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }])
                                      }
                                      else {
                                        displayDraftTasksAndBugsErrorsSet([{ message: "Could not delete the draft bug.", messageType: DisplayMessageType.Error }])
                                      }
                                    }
                                  }
                                }
                              }}
                            />
                          </div>
                        </div>
                      </div>

                      <hr />
                      <div className="row">
                        <div className="row" style={{ marginTop: 10 }}>
                          <HeadingComponent title="Published Tasks and Bugs" />
                        </div>
                        <div className="row">
                          {
                            dataWorkItems && dataWorkItems.filter(x => x.taskId && x.taskId.length > 0).length > 0 ? <ReviewTasksnBugsComponent
                              workItems={dataWorkItems}
                            />
                              : <Text>No published bugs or tasks present.</Text>
                          }
                        </div>
                      </div>

                      <div className="row" style={{ height: 300 }}></div>
                    </PivotItem>
                    <PivotItem headerText="Review State">
                      <div className="row" style={{ marginLeft: 1 }}>
                        <div className="d-flex" style={{ padding: 0 }}>
                          <HeadingComponent title="Review State" subtitle="" />
                          <div className="ms-auto">
                            <Stack tokens={stackTokens} horizontal>
                              {/* <StackItem>
                                                    <DefaultButton text="lock" title="Locks the review from edits by others" />
                                                </StackItem> */}
                              <StackItem>
                                <SpinnerPrimaryButton onclick={onReviewSaveClick} text={"Save"} executingText={"Saving review information"} />
                              </StackItem>
                            </Stack>
                          </div>
                        </div>
                      </div>


                      <div className="row">
                        <div className="col-2">
                          <ComboBox
                            label="Review Status"
                            options={cmbOptionsReviewStatus}
                            selectedKey={cmbSelectedOptionReviewStatus}
                            useComboBoxAsMenuWidth={true}
                            onChange={onReviewStatusChange}
                          />
                        </div>
                        <div className="col-2">
                          <ComboBox
                            label="Overwrite Service Priority"
                            options={cmbOptionsServicePriority}
                            selectedKey={cmbSelectedOptionServicePriority}
                            useComboBoxAsMenuWidth={true}
                            onChange={onServicePriorityChange}
                            disabled={true}
                          // disabled={dataReviewRaw?.securityReviewTeam == null ? true : false}
                          // changes for disabling the dropdown of priority overwrite. This will be diabled until SDLP is up and effecting changes in ServiceTree priority.
                          />
                        </div>

                        <div className="col-2">
                          <SpinButton
                            label="Time spent (in min)"
                            labelPosition={Position.top}
                            value={timeSpentValue}
                            onChange={onTimeSpentChange}
                            min={0}
                            max={1000}
                            step={15}
                            incrementButtonAriaLabel="Increase value by 15"
                            decrementButtonAriaLabel="Decrease value by 15"
                            styles={spinButtonStyles}
                          />
                        </div>

                        <div className="col-2">
                          <DatePicker
                            label="Review Complete date"
                            value={reviewCompleteDate}
                            onSelectDate={reviewCompleteDateSet as (date: Date | null | undefined) => void}
                            styles={datePickerStyles}
                            // DatePicker uses English strings by default. For localized apps, you must override this prop.
                            placeholder="Select a date..."
                            ariaLabel="Select a date"
                            maxDate={maxDate}
                            strings={defaultDatePickerStrings}
                            allowTextInput
                          />
                        </div>

                        <div className="col-4">
                          <Label>Reviewers</Label>
                          <MsPeoplePickerComponent
                            itemLimit={50}
                            selectedUsers={dataSelectedReviewers}
                            onUserSelected={(selectedUsers: UserProfile[]) => {
                              if (selectedUsers && selectedUsers.length > 0) {
                                dataSelectedReviewersSet(selectedUsers);
                              }
                              else {
                                dataSelectedReviewersSet([]);
                              }
                            }} />
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-2">
                          <div className="row"> <Label>Additional Details for review </Label></div>
                          <div className="row"> <Text>{dataReview?.additionalDetails} </Text> </div>
                        </div>
                        <div className="col-6">
                          <div className="row"> <Label>Technology Tags </Label></div>
                          <div className="row">
                            <div className="d-flex flex-wrap">
                              {
                                dataReview && dataReview.technologyTags && dataReview.technologyTags.length > 0 &&
                                dataReview.technologyTags.map(tag => {
                                  return <div key={tag.id} style={{ marginRight: 3 }}>
                                    <Text variant="smallPlus">{tag.name}, </Text>
                                  </div>
                                })

                              }
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <HeadingComponent title="Notes to Service Team" subtitle="Use this section to communicate to service team." />
                      </div>

                      <div className="row">

                        <Label>Notes To Service Team</Label>
                        <div style={{ padding: 10 }}>
                          <Editor
                            init={{
                              skin: false,
                              content_css: false,
                              height: 300,
                              menubar: false,
                              statusbar: false,
                              paste_data_images: true,
                              plugins: [
                                'link image lists advlist table paste charmap fullscreen hr'
                              ],
                              toolbar1:
                                'undo redo | styleselect | fontsizeselect fontselect | bold italic underline| \
                 alignleft aligncenter alignright alignjustify | forecolor backcolor',
                              toolbar2: 'bullist numlist outdent indent | removeformat \
                       table link image charmap | fullscreen hr | superscript subscript| hyperlinkButton' ,
                              table_default_attributes: {
                                border: '1'
                              },

                            }}
                            value={notesToTeam}
                            onEditorChange={onNotesToTeamChange}
                            disabled={dataReview.reviewStatus === ReviewStatusStrings.Completed}
                          />
                        </div>
                      </div>

                    </PivotItem>
                  </Pivot>
                </div>
              </PivotItem>
            }
          </Pivot>
        </div>
      }
      {/* The review detail edit panel */}
      <Panel
        type={PanelType.medium}
        isOpen={isReviewDetailEditModalOpen}
        onDismiss={hideReviewDetailEditModal}
        closeButtonAriaLabel="Close"
        headerText={"Edit Review Details"}
        onRenderFooterContent={onReviewDetailFooterContent}
      >
        <div className="row">
          <MessageComponent messages={displayEditPanelMessages} />
        </div>

        <div className="row">
          <Label htmlFor="ServiceChangePicker">
            Service Name
          </Label>

          <ServiceTreeServicePickerComponent
            itemLimit={1}
            id='ServiceChangePicker'
            SelectedServices={dataSelectedServiceTreeService ? [dataSelectedServiceTreeService] : []}
            onServiceSelected={(selectedServices) => {
              if (selectedServices && selectedServices.length > 0 && selectedServices[0]) {
                console.log(selectedServices[0]);
                serviceChange(selectedServices[0]);
              }
            }} />
        </div>

        <div className="row">

          <ReviewTypeComponent
            label="Review Type"
            selectedTmMeetingType={selectedTmMeetingType}
            showThreatModelReview={teamMetadata?.allowTM !== undefined ? teamMetadata.allowTM : true}
            showSecurityConsultation={teamMetadata?.allowSC !== undefined ? teamMetadata.allowSC : true}
            onReviewTypeSelected={(selectedReviewTypeString: string) => {
              console.log(selectedReviewTypeString);
              selectedTmMeetingTypeSet(selectedReviewTypeString);
            }} />
        </div>

        <div className="row">
          <Label htmlFor="OtherMembersPicker">
            Other members
          </Label>
          <MsPeoplePickerComponent id='OtherMembersPicker'
            itemLimit={50}
            selectedUsers={dataSelectedOtherTeamMembers}
            onUserSelected={(selectedUsers) => {
              if (selectedUsers && selectedUsers.length > 0) {
                dataSelectedOtherTeamMembersSet(selectedUsers);
              }
              else {
                dataSelectedOtherTeamMembersSet([]);
              }
            }} />
        </div>

        <div className="row">
          <Label htmlFor="OtherserviceInteractions">
            Select other services your service interacts with.
          </Label>
          <ServiceTreeServicePickerComponent itemLimit={100}
            id='OtherserviceInteractions'
            SelectedServices={selectedOtherInteractionServiceTreeServices}
            onServiceSelected={(selectedServices) => {
              if (selectedServices && selectedServices.length > 0) {
                selectedOtherInteractionServiceTreeServicesSet(selectedServices);
              }
            }} />
        </div>

        <div className="row">
          <Label>Enter the technologies used in the App.</Label>
          <TechnologyTagsComponent
            selectedTechnologies={selectedTechnologyTags}
            onTechTagSelected={(selectedTags: TechTag[]) => {
              selectedTechnologyTagsSet(selectedTags);
            }} />
        </div>

        <div className="row">
          <TextField
            styles={{

            }}
            multiline={true}
            label="Additional Details"
            name="additionaldetails"
            value={additionalRequestDetails}
            onChange={onAdditionalDetailsChange}
          />
        </div>
      </Panel>


      {/* The meeting reschedule edit panel */}

      <Panel
        type={PanelType.medium}
        isOpen={isMeetingDetailEditModalOpen}
        onDismiss={() => { dataSelectedSlotSet(undefined); hideMeetingDetailEditModal(); }}
        closeButtonAriaLabel="Close"
        headerText={"Reschedule the meeting."}
        onRenderFooterContent={onMeetingDetailFooterContent}
      >
        <div className="row">
          <MessageComponent messages={displayReschedulePanelMessages} />
        </div>

        <div className="row">
          <Label htmlFor="OtherserviceInteractions">
            Select available date to change the slot.
          </Label>
          {
            dataReview && dataReview.securityReviewTeam &&
            <AvailableSlotsComponent
              reviewTeam={dataReview?.securityReviewTeam}
              slotButtonText="Select Slot"
              onSlotSelcted={(selectedSlot: Slot) => {
                console.log(selectedSlot);
                dataSelectedSlotSet(selectedSlot);
              }} />
          }
        </div>

        <div className="row">
          <div className="col">
            <Label>Scheduled Slot</Label>
            <Stack horizontal tokens={stackTokens}>
              <StackItem>
                {getDateFormat(dataReview?.meetingsData?.browserLocalTime) + '-' + getTimeFormat(dataReview?.meetingsData?.browserLocalTime)}
              </StackItem>
              <StackItem>
                <MeetingTimeCalloutComponent meetingData={dataReview?.meetingsData} />
              </StackItem>
            </Stack>
          </div>
        </div>

        <div className="row">
          <div className="col">
            <Label>New Slot</Label>
            {dataSelectedSlot?.meetingsData ? <Stack horizontal tokens={stackTokens}>
              <StackItem>
                {getDateFormat(dataSelectedSlot?.meetingsData?.browserLocalTime) + '-' + getTimeFormat(dataSelectedSlot?.meetingsData?.browserLocalTime)}
              </StackItem>
              <StackItem>
                <MeetingTimeCalloutComponent meetingData={dataSelectedSlot?.meetingsData} />
              </StackItem>
            </Stack>
              :
              <Text>Select a slot from above.</Text>
            }
          </div>
        </div>

      </Panel>

      {/* The meeting adhoc reschedule by reviewer panel */}
      <Panel
        type={PanelType.medium}
        isOpen={isAdhocRescheduleEditModalOpen}
        onDismiss={hideAdhocRescheduleEditModal}
        closeButtonAriaLabel="Close"
        headerText={"Reschedule the meeting to adhoc timing."}
        onRenderFooterContent={onAdhocRescheduleFooterContent}>
        {
          dataReview && dataReview.isReviewerForReview && dataReview.securityReviewTeam && dataReview.securityReviewTeam.id &&
          <div className="row" >
            <hr />
            <div className="row">
              <MessageComponent messages={displayAdhocReschedulePanelMessages} />
            </div>
            <div className="row">
              <Text>As reviewer in a security team you can reschedule the meeting to any adhoc slot as per your convienience.</Text>
            </div>
            <div className="row">
              <div className="col">
                <div className="row">
                  <Label>
                    Date of rescheduled adhoc Review
                  </Label>
                  <DatePicker
                    value={selectedAdhocRescheduleDate}
                    onSelectDate={selectedAdhocRescheduleDateSet as (date: Date | null | undefined) => void}
                    placeholder="Select of review"
                    ariaLabel="Select date of review"
                    minDate={minDate}
                    // DatePicker uses English strings by default. For localized apps, you must override this prop.
                    strings={defaultDatePickerStrings}
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="row">
                <div className="col">
                  <TimeZoneDropdownComponent
                    label="Timezone."
                    placeholder="Select timezone."
                    onTimeZoneSelected={((selectedTimeZone?: TMPTimeZone) => {
                      if (selectedTimeZone) {
                        reviewTimeZoneSet(selectedTimeZone);
                      }
                    })} />

                </div>
                <div className="col">
                  <TimeDropDownComponent
                    label="Time of Meeting"
                    placeholder="Select time of meeting"
                    onTimeSelected={(selectedMeetingTime: MeetingTime) => {
                      reviewTimeSet(selectedMeetingTime);
                    }} />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Label>Duration</Label>

                  <Stack horizontal tokens={stackTokens}>
                    <Checkbox label="1 Hour" checked={is1hrMinSlot} onChange={on1hrSlotCheck} />
                    <Checkbox label="30 min" checked={is30MinSlot} onChange={on30MinSlotCheck} />
                  </Stack>
                </div>
              </div>
            </div>
          </div>
        }
      </Panel>

      {/* Service team followup response panel */}
      <Panel
        type={PanelType.medium}
        isOpen={isFollowUpAnswerEditModalOpen}
        onDismiss={hideFollowUpEditModal}
        closeButtonAriaLabel="Close"
        headerText={"Follow Up."}
        onRenderFooterContent={onFollowUpEditModalFooterContent}>
        {
          currentFollowup &&
          <div className="row">
            <div className="row">
              <Text>{currentFollowup.question}</Text>
            </div>
            <div className="row">
              <div style={{ padding: 10 }}>
                <Editor
                  init={{
                    skin: false,
                    content_css: false,
                    height: 300,
                    menubar: false,
                    statusbar: false,
                    paste_data_images: true,
                    plugins: [
                      'link image lists advlist table paste charmap fullscreen hr'
                    ],
                    toolbar1:
                      'undo redo | styleselect | fontsizeselect fontselect | bold italic underline| \
                                             alignleft aligncenter alignright alignjustify | forecolor backcolor',
                    toolbar2: 'bullist numlist outdent indent | removeformat \
                                                   table link image charmap | fullscreen hr | superscript subscript| hyperlinkButton' ,
                    table_default_attributes: {
                      border: '1'
                    },

                  }}
                  value={currentFollowup.answer ? currentFollowup.answer : ""}
                  onEditorChange={(s, e) => {
                    currentFollowupSet({
                      id: currentFollowup.id,
                      question: currentFollowup.question,
                      answer: s
                    });
                  }}
                  disabled={false}
                />
              </div>
            </div>
          </div>
        }

      </Panel>

      {/* Delete panel */}
      <Panel
        type={PanelType.medium}
        isOpen={isDeleteModalOpen}
        onDismiss={hideDeleteModal}
        closeButtonAriaLabel="Close"
        headerText={"Delete Review " + dataReview?.easyReviewId}
        onRenderFooterContent={onReviewDeleteFooterContent}
      >
        <div className="row">
          <MessageComponent messages={displayDeletePanelMessages} />
        </div>

        <div>
          {dataReview && dataReview.easyReviewId &&
            <TextField
              multiline={false}
              required={true}
              label={"Confirm delete"}
              placeholder="type 'yes' for confirmation"
              name="deleteConfitmation"
              value={confirmDeleteText}
              onChange={onConfirmDeleteTextChange}
            />
          }
        </div>
      </Panel>

      {/* Tasks and bugs create / Edit panel*/}
      <Panel
        type={PanelType.medium}
        isOpen={isTasksNBugsModalOpen}
        onDismiss={hideTasksNBugsModalModal}
        closeButtonAriaLabel="Close"
        headerText={"Tasks and Bugs"}
        hasCloseButton={false}
        onRenderFooterContent={onTasksNBugsFooterContent}
        onRenderNavigationContent={onTasksNBugsFNavigationContent}
      >
        <div className="row">
          <MessageComponent messages={displayDraftTasksAndBugsErrors} />
        </div>
        {

          // dataReview.reviewStatus !== ReviewStatusStrings.Completed &&
          <div className="row">
            <TasksBugsCreateComponent
              showInline={false}
              onCloseButtonClick={(isTaskPublishedOrDraft: boolean) => {
                hideTasksNBugsModalModal();
                if (!isTaskPublishedOrDraft && workItemForEdit) {
                  draftDataWorkItems.push(workItemForEdit);
                  draftDataWorkItemsSet([...draftDataWorkItems]);
                }
              }}
              onAddtoDraft={async (wkItem: ReviewWorkItem) => {
                if (wkItem && reviewId && serviceId) {
                  var response = await reviewService.saveDraftWorkItem(wkItem, reviewId, serviceId)
                  if (response && !response.hasErrors && response.result) {
                    var remainingItems = draftDataWorkItems.filter(x => x.referenceId !== wkItem.referenceId)
                    if (remainingItems && remainingItems.length > 0) {
                      draftDataWorkItemsSet([...remainingItems, wkItem]);
                    }
                    else {
                      draftDataWorkItemsSet([wkItem]);
                    }

                    if (!isTasksNBugsModelPinned) {
                      hideTasksNBugsModalModal();
                    }

                    workItemForEditSet(undefined);
                  }
                  else {
                    if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                      displayDraftTasksAndBugsErrorsSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                    }
                    else {
                      displayDraftTasksAndBugsErrorsSet([{ message: "The publish failed.", messageType: DisplayMessageType.Error }]);
                    }
                  }

                }
              }}
              onPublishtoADO={async (wkItem: ReviewWorkItem) => {
                if (wkItem) {
                  let data: PublishTasksAndBugs = {
                    reviewId: reviewId,
                    serviceId: serviceId,
                    eTag: dataReview?.eTag,
                    tasksAndBugsTobePublished: [wkItem]
                  };

                  var response = await reviewService.publishTasksAndBugs(data);
                  if (response && !response.hasErrors && response.result) {
                    dataReviewRawSet(response.result);
                    displayDraftTasksAndBugsErrorsSet([{ message: "The publish was successful.", messageType: DisplayMessageType.Success }]);
                    if (!isTasksNBugsModelPinned) {
                      hideTasksNBugsModalModal();
                    }
                  }
                  else {
                    if (response && response.hasErrors && response.businessErrors && response.businessErrors.length > 0 && response.businessErrors[0].message) {
                      displayDraftTasksAndBugsErrorsSet([{ message: response.businessErrors[0].message, messageType: DisplayMessageType.Error }]);
                    }
                    else {
                      displayDraftTasksAndBugsErrorsSet([{ message: "The publish failed.", messageType: DisplayMessageType.Error }]);
                    }
                  }
                }
              }}
              weaknessTypes={dataWeaknessTypes}
              selectedWorkItem={workItemForEdit}
              onTaskTypeChange={(taskType: string) => {
                displayDraftTasksAndBugsErrorsSet([]);
              }}
            />
          </div>
        }
      </Panel>
      {/* /* changes for future dated review */}
      <Panel
        type={PanelType.medium}
        isOpen={isFutureDateModalOpen}
        onDismiss={hideisFutureDateReviewModal}
        closeButtonAriaLabel="Close"
        headerText="Alert"
        id="reviewFutureDateInfoPanel"
        onRenderFooterContent={onRenderFDFooterContent}
      >
        <div className="row">
          <MessageComponent messages={displayFDReviewPanelMessages} />
        </div>
        <div className="row"> <Text><b><i>This Review is scheduled for future date.</i></b></Text></div><div><br /><br /></div>
        <div className="row"> <Text>The Actual Date of Review is: {getDateFormat(dataReview?.meetingsData?.dateOfReview) + '-' + getTimeFormat(dataReview?.meetingsData?.dateOfReview)} </Text></div>
        <div className="row"> <Text>Current date and time is:  {getDateFormat(gettingcurrentdate) + '-' + getTimeFormat(gettingcurrentdate)} </Text></div>
      </Panel>
      {/* /* changes for future dated review */}

    </>);
}